// Libraries
import { defineStore } from "pinia";
import {
  getAuth,
  signOut,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithEmailAndPassword
} from "firebase/auth";
import { type FirebaseError } from "@firebase/app";

// Composables
import { useUser } from "@/composables/useUser";
import { useActiveCampaignTag } from "@/composables/useActiveCampaignTag";

// Services & Helpers
import { projectAuth } from "@/firebase/config";
import { storageSession } from "@/utils/storage";
import { bannerService } from "@/core/services/bannerService";
import { handleRuntimeErrors } from "@/helpers/error-handler";
import { locationService } from "@/core/services/location.service";
import { apiService } from "@/core/services/apiService";
import { analyticsReset, analyticsSetUser, analyticsTrackEvent } from "@/core/services/analyticsService";

// Types
import { UserDetailsAdmin, UserStoreT, UserT, SignupDataT } from "@/@types/user";
import { ApiResponse } from "@/@types/api";

export interface CustomError extends Error {
  code?: string;
  error?: string;
}

export const useUserStore = defineStore({
  id: "User",
  state: (): UserStoreT => ({
    userId: "",
    name: "",
    userInfos: {} as UserDetailsAdmin,
    error: undefined,
    loading: false,
    showLoadingModal: false,
    showWelcomeModal: false,
    hasAgreedToLatestTermsOfUse: false
  }),
  persist: true,
  getters: {},
  actions: {
    async logout() {
      this.$reset();

      storageSession.clear();

      try {
        await signOut(getAuth());
        await analyticsReset();
        analyticsTrackEvent({ name: "Logout", properties: { email: this.userInfos.email } });
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async loginByUsername(data: { email: string; password: string }, showError = true) {
      const auth = getAuth();

      const { userStatus } = useUser();

      this.error = undefined;

      try {
        storageSession.clear();

        const { user } = await signInWithEmailAndPassword(auth, data.email, data.password);

        await apiService.refresh();

        bannerService.enableBanner();
        const userInfos = await apiService.get<UserDetailsAdmin>({ endpoint: `users/${user.uid}` });

        if (!userInfos.data) {
          throw new Error("Falha ao obter informações do usuário");
        }

        storageSession.setItem("user", {
          username: user.displayName,
          email: user.email,
          userId: user.uid
        });

        this.name = user.displayName ?? "";
        this.userId = user.uid;
        this.userInfos = userInfos.data;

        userStatus.value = this.userInfos;

        analyticsSetUser(userStatus.value);
        analyticsTrackEvent({ name: "Login", properties: { email: user.email } });

        return userStatus.value;
      } catch (err: any) {
        this.error = err.message;

        if (showError) handleRuntimeErrors(err.code);

        return err;
      }
    },
    async signup(dataForm: SignupDataT): Promise<ApiResponse["errorData"] | void> {
      this.error = undefined;

      try {
        const res = await apiService.post<{ errorData: ApiResponse["errorData"] }>({
          endpoint: "users",
          body: { ...dataForm }
        });

        return res.errorData ?? {};
      } catch (err) {
        handleRuntimeErrors(err);
      }
    },
    async signupByGoogle() {
      const provider = new GoogleAuthProvider();
      this.loading = true;

      try {
        await signInWithRedirect(projectAuth, provider);
        this.loading = false;
        return;
      } catch (err: unknown) {
        const error = err as CustomError;
        this.loading = false;
        handleRuntimeErrors(err);
        return error;
      }
    },
    async getGoogleSignupResult() {
      try {
        const res = await getRedirectResult(projectAuth);

        if (res) {
          // This gives you a Google Access Token. You can use it to access Google APIs.
          const credential = GoogleAuthProvider.credentialFromResult(res);
          const token = credential?.accessToken;

          // The signed-in user info.
          const user = res.user;
          const isNewUser = getAdditionalUserInfo(res)?.isNewUser;

          return {
            token: token,
            user: user,
            isNewUser: isNewUser
          };
        }
        return;
      } catch (err: unknown) {
        const error = err as { code: string; message: string; customData: { email: string } };
        const errorCode = error.code;
        const errorMessage = error.message;

        // The email of the user's account used.
        const email = error.customData.email;

        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(err as FirebaseError);

        console.log("Error code:" + errorCode);
        console.log("Error message:" + errorMessage);
        console.log("User e-mail:" + email);
        console.log("Auth credential type:" + credential);

        handleRuntimeErrors(err);

        return err;
      }
    },
    async updateUser(user: Partial<UserT>) {
      return await apiService.patch({ endpoint: "users", body: user });
    },
    async handleInitTrial(checkoutLink?: string, openOnNewTab?: boolean) {
      const { updateStatus } = useUser();
      const { triggerTag } = useActiveCampaignTag();

      const addTagActiveCampaign = async () => {
        if (checkoutLink && !openOnNewTab) await triggerTag("299");
      };

      this.loading = true;
      await Promise.all([
        await this.updateUser({ onboarding: { hasCompleted: true } }),
        await updateStatus(),
        await addTagActiveCampaign()
      ]);
      this.loading = false;

      if (checkoutLink) {
        if (openOnNewTab) {
          window.open(checkoutLink, "_blank", "noopener,noreferrer");
          return;
        }

        window.location.href = checkoutLink;
      }
    },
    async checkLatestTermsOfUseAgreement() {
      const { data } = await apiService.get<{ hasAgreedToLatestTermsOfUse: boolean }>({
        endpoint: "/terms-of-use/agreement/check"
      });
      this.hasAgreedToLatestTermsOfUse = data?.hasAgreedToLatestTermsOfUse ?? false;

      return data?.hasAgreedToLatestTermsOfUse;
    },
    async agreeToTermsOfUse(termsOfUseId: string) {
      let userIP = "Desconhecido";
      let userLocation = "Localização não disponível";

      try {
        const locationData = await locationService.getUserLocation();

        userIP = locationData.userIP;
        userLocation = locationData.userLocation;
      } catch (error) {
        console.error("Erro ao obter localização do usuário:", error);
      }

      try {
        await apiService.post({
          endpoint: "/terms-of-use/agreement",
          body: {
            termsOfUseId,
            userLocation,
            userIP
          }
        });

        this.hasAgreedToLatestTermsOfUse = true;
      } catch (error) {
        console.error("Erro ao concordar com os termos de uso:", error);
        throw error;
      }
    }
  }
});

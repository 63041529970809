// Services & Helpers
// import { withScope, captureException } from "@sentry/vue";
import { validateSourceErrors } from "@/core/services/error/customErrors";

// Composables
import { toast } from "@/composables/useCustomToast";
// import { useUser } from "@/composables/useUser";

// Types
import { ToastErrorMessage } from "@/core/services/error/types";

type ErrorExtraFields = {
  [key: string]: string | number | boolean;
};

export function handleRuntimeErrors(
  error: unknown,
  { title, message, showToast = true }: Partial<ToastErrorMessage> = {},
  extraFields: ErrorExtraFields = {}
) {
  // const { userStatus } = useUser();
  const errorCode = (error as any)?.code || error || "default";
  const customError =
    validateSourceErrors[errorCode as keyof typeof validateSourceErrors] || validateSourceErrors.default;

  const errorTitle = title || customError.title;
  const errorMessage = message || customError.message;

  // withScope(scope => {
  //   scope.setExtra("message", message);
  //   scope.setExtra("teamId", userStatus.value?.activeTeam?.id);
  //   scope.setExtra("projectId", userStatus.value?.lastActiveProject?.id);
  //   scope.setExtra("role", userStatus.value?.activeTeam?.role);
  //   scope.setExtra("title", errorTitle);
  //   scope.setExtra("message", errorMessage);
  //   scope.setExtra("extra", extraFields);
  //   scope.setExtra("errorCode", errorCode);
  // captureException(error);
  // });

  if (showToast) toast.error({ title: errorTitle, message: errorMessage });
}

// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";

// Stores
import { useUserStore } from "@/store/user";

const requireAdmin: NavigationGuard = async (_to, _from, next) => {
  try {
    const userStore = useUserStore(pinia);

    const infos = userStore.userInfos;

    if (infos?.role !== "admin") {
      next({ name: "dashboard" });
    } else {
      next();
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireAdmin;

import { sessionStorageProxy, storageSession } from "./../../utils/storage";

export class BannerStateProxy {
  storageSession: sessionStorageProxy<unknown>;
  constructor() {
    this.storageSession = storageSession;
  }

  disableBanner() {
    this.storageSession.setItem("copybase-banner-disabled", true);
  }

  enableBanner() {
    this.storageSession.setItem("copybase-banner-disabled", false);
  }

  isBannerDisabled() {
    return this.storageSession.getItem("copybase-banner-disabled");
  }
}

export const bannerService = new BannerStateProxy();

// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";

// Composables
import { isUserTeamMember } from "@/composables/useUser";

// Stores
import { useTeamStore } from "@/store/team";

const requireTeamAdmin: NavigationGuard = (_to, _from, next) => {
  try {
    const teamStore = useTeamStore(pinia);

    if (
      teamStore.isPlanInvalid ||
      teamStore.isSubscriptionLate ||
      (!isUserTeamMember.value && !teamStore.isPlanInvalid && !teamStore.isSubscriptionLate)
    ) {
      next();
    } else {
      next("/dashboard");
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireTeamAdmin;

// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";

// Stores
import { useTeamStore } from "@/store/team";

const requireLeadMachine: NavigationGuard = (_to, from, next) => {
  try {
    const teamStore = useTeamStore(pinia);

    if (teamStore.teamHasLeadMachine) {
      next();
    } else {
      next(from);
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireLeadMachine;

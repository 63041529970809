// Core
import { ref } from "vue";

// Libraries
import localForage from "localforage";

// Services & Helpers
import { modelsConfig, configVersion } from "@/core/config/modelsConfig";

// Types
import { NewModelCategory, ModelsGroup } from "@/@types/models";

const CONFIG_VERSION_KEY = "modelsConfigVersion";

export const useModelsConfig = () => {
  const config = ref<Partial<Record<NewModelCategory, ModelsGroup>>>(modelsConfig);

  const updateConfig = async (newConfig: Partial<Record<NewModelCategory, ModelsGroup>>) => {
    config.value = newConfig;

    await localForage.setItem("modelsConfig", newConfig);
    await localForage.setItem(CONFIG_VERSION_KEY, configVersion);
  };

  const loadConfig = async () => {
    const storedConfig = await localForage.getItem("modelsConfig");
    const storedVersion = await localForage.getItem(CONFIG_VERSION_KEY);

    if (storedConfig && storedVersion === configVersion) {
      config.value = storedConfig;
    } else {
      await updateConfig(modelsConfig);
    }
  };

  loadConfig();

  return { config };
};

// Libraries
import axios from "axios";

class LocationService {
  async getUserLocation() {
    const ipServices = [
      "https://ipv4.seeip.org/jsonip",
      "https://api.ipify.org?format=json",
      "https://api.ipify.org",
      "https://ipapi.co/json/",
      "http://ip-api.com/json/",
      "https://reallyfreegeoip.org/json/",
      "https://freegeoip.app/json/"
    ];

    let userIP;

    for (const service of ipServices) {
      try {
        const { data } = await axios.get(service, { timeout: 5000 });

        userIP = data.ip || data;

        if (userIP) break;
      } catch (error) {
        console.error(`Erro ao obter IP de ${service}:`, error);
      }
    }

    if (!userIP) {
      console.warn("Não foi possível obter o IP do usuário.");
      return { userIP: "Desconhecido", userLocation: "Localização não disponível" };
    }

    try {
      const { data: loc } = await axios.get(`https://ipapi.co/${userIP}/json/`, { timeout: 5000 });

      return {
        userIP,
        userLocation: `${loc.city}, ${loc.region_code} - ${loc.country_name}`
      };
    } catch (error) {
      console.error("Erro ao obter localização:", error);
      return { userIP, userLocation: "Localização não disponível" };
    }
  }
}

const locationService = new LocationService();

export { locationService };

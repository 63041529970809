// Core
import type { App } from "vue";

// Libraries
import Toast, { POSITION, type PluginOptions } from "vue-toastification";

export function initToast(app: App<Element>) {
  const toastOptions: PluginOptions = {
    position: POSITION.TOP_RIGHT,
    // NOTE: O campo duration teve que ser adicionado em @/components/toast/ToastItem.vue, pois, por alguma razão o campo timeout
    //       no arquivo @/plugins/toast não está funcionando
    timeout: 5000,

    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.7,
    showCloseButtonOnHover: false,
    hideProgressBar: true,

    closeButton: false,
    icon: false,
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(t => t.type === toast.type).length !== 0) {
        return false;
      }
      return toast;
    }
  };

  app.use(Toast, toastOptions);
}

// Services & Helpers
import { defaultErrorMessages } from "./defaultErrors";
import { handleRuntimeErrors } from "@/helpers/error-handler";

// Types
import { ApiErrorCode } from "@/@types/api";
import { ErrorTable, ToastErrorMessage } from "./types";

export class ErrorHandlerService {
  private errorTable: Partial<ErrorTable>;
  private localCustomErrorTable: Partial<ErrorTable> | undefined;

  constructor(errorTable = defaultErrorMessages, localCustomErrorTable?: Partial<ErrorTable>) {
    this.errorTable = errorTable;
    this.localCustomErrorTable = localCustomErrorTable;
  }

  handleError(
    error?: ApiErrorCode | unknown,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast?: boolean
  ): Partial<ToastErrorMessage>;

  handleError(
    error?: string,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast?: boolean
  ): Partial<ToastErrorMessage>;

  handleError(
    error?: ApiErrorCode,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast = true
  ) {
    const errorMessage = this.getDefaultErrorMessage(error || "default", customMessage);
    const errorMessageWithParams = errorMessage?.message?.replaceAll(
      /\{\{(\w+)\}\}/g,
      (match, key) => param?.[key] || match
    );
    errorMessage.message = errorMessageWithParams || errorMessage.message;
    handleRuntimeErrors(error, { ...errorMessage, showToast: showToast });
    return errorMessage;
  }

  getDefaultErrorMessage(code: ApiErrorCode | string, custom?: Partial<ErrorTable>): Partial<ToastErrorMessage> {
    if (!custom) custom = this.localCustomErrorTable;

    const defaultErrorMessage = custom?.default || this.errorTable[code] || this.errorTable.default!;
    const customErrorMessage = custom?.[code];

    if (!custom || !customErrorMessage) {
      return defaultErrorMessage;
    }

    if (customErrorMessage.message && customErrorMessage.title) return customErrorMessage;
    if (!customErrorMessage?.message) customErrorMessage.message = defaultErrorMessage?.message;
    if (!customErrorMessage?.title) customErrorMessage.title = defaultErrorMessage?.title;

    return customErrorMessage;
  }
}

export const errorHandlerService = new ErrorHandlerService(defaultErrorMessages);

// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";

// Stores
import { useTeamStore } from "@/store/team";

const requirePlan: NavigationGuard = async (to, _from, next) => {
  try {
    const teamStore = useTeamStore(pinia);

    if (teamStore.teamIsLeadMachineOnly) {
      if ((to.name as string)?.includes("leads")) {
        next();
      } else {
        next({ name: "leads" });
      }
    } else {
      next();
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requirePlan;

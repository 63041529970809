// Libraries
import { pinia } from "@/main";
import { defineStore } from "pinia";

// Services & Helpers
import { formatQueryParams } from "@/utils/format";
import { apiService } from "@/core/services/apiService";
import { errorHandlerService } from "@/core/services/error";
import { handleRuntimeErrors } from "@/helpers/error-handler";

// Composables
import { useUser } from "@/composables/useUser";
import { toast } from "@/composables/useCustomToast";

// Stores
import { useTeamStore } from "./team";
import { useProjectCreationStore } from "./project-creation";

// Types
import { User } from "@/@types/team";
import { Project } from "@/@types/project";
import { Persona } from "@/@types/persona";
import { ProjectFilter } from "@/@types/project";

export type ProjectStoreStateType = {
  projects: Project[];
  currentProject?: Project;
  totalProjectsCount: number;
  showDeleted: boolean;
  loading: boolean;
  loadingAudiences: boolean;
  listingPage: number;
  audiences: (Persona & { checked: boolean })[];
  audienceModal: boolean;
  deleteAudienceModal: boolean;
  selectedAudience: Persona | null;
  modalAction: "create" | "edit";
  update: {
    name: string;
    coverUrl: string | null;
    description: string;
    differentials: string;
    language: string;
    mustHave: string;
    mustNotHave: string;
    brandVoice: {
      brandVoices: { label: string; checked: boolean }[];
      tones: { label: string; checked: boolean }[];
      languages: { label: string; checked: boolean }[];
      perspectives: { label: string; checked: boolean }[];
    };
    tags: string[];
    members: string[];
  };
};

const { userStatus, updateStatus } = useUser();

export const MAX_PROJECTS_PER_FETCH = 15;

type ProjectFetchResponse = {
  projects: Project[];
  totalProjectsCount: number;
};

export const useProjectStore = defineStore({
  id: "Project",
  state: (): ProjectStoreStateType => ({
    projects: [] as Project[],
    currentProject: undefined,
    totalProjectsCount: 0,
    showDeleted: false,
    loading: false,
    loadingAudiences: false,
    listingPage: 1,
    audiences: [],
    audienceModal: false,
    deleteAudienceModal: false,
    selectedAudience: null,
    modalAction: "create",
    update: {
      name: "",
      coverUrl: "",
      description: "",
      differentials: "",
      language: "",
      mustHave: "",
      mustNotHave: "",
      brandVoice: {
        brandVoices: [],
        tones: [],
        languages: [],
        perspectives: []
      },
      tags: [],
      members: []
    }
  }),
  actions: {
    // NOTE: Essa é a função mais generalista para realizar buscas de projetos
    // ela recebe como parâmetro um objeto com os filtros que serão aplicados
    async fetch(filter?: ProjectFilter) {
      let query: string = "";

      if (filter) {
        query = formatQueryParams(filter);
      }

      const teamId = userStatus.value?.activeTeam?.id;

      if (!teamId) return;

      const url = getProjectFetchUrlByUserRole(
        `projects?teamId=${teamId}&amount=${MAX_PROJECTS_PER_FETCH}${query && "&" + query}`
      );

      try {
        const { data, error } = await apiService.get<ProjectFetchResponse>({ endpoint: url });
        await this.fetchCurrentProject();

        if (!data || error) {
          return;
        }

        this.projects = data.projects || data || [];
        this.totalProjectsCount = data.totalProjectsCount;

        if (filter?.shouldIgnoreSave) {
          return data.projects;
        }
      } catch (error) {
        handleRuntimeErrors(error);
        return;
      }
      return;
    },
    async fetchCurrentProject(projectId?: string) {
      const creationStore = useProjectCreationStore(pinia);

      creationStore.projectLoading = true;

      const id = userStatus.value?.lastActiveProject?.id;
      const url = `projects/${projectId || id}`;

      const { data, error } = await apiService.get<Project>({ endpoint: url });

      if (!data || error) {
        return { error };
      }

      this.currentProject = data;

      creationStore.projectLoading = false;

      return { data };
    },
    async fetchProjects(deleted = false, dates: [string, string] | null = null, name?: string) {
      const teamId = userStatus.value?.activeTeam?.id;

      let url = getProjectFetchUrlByUserRole(
        `projects?teamId=${teamId}&simplified=true&deleted=${deleted}&amount=${MAX_PROJECTS_PER_FETCH}${name ? `&name=${name}` : ""}`
      );

      if (dates) {
        url += `&startDate=${dates[0]}&endDate=${dates[1]}`;
      }

      if (!teamId) return;

      try {
        const { data, error } = await apiService.get<ProjectFetchResponse>({ endpoint: url });

        if (!data || error) {
          return;
        }

        this.projects = data.projects || data || [];
        this.totalProjectsCount = data.totalProjectsCount;

        await this.fetchCurrentProject();
        await this.includeProject();
      } catch (error) {
        handleRuntimeErrors(error);
      }
    },
    async switchProject(id: string, successMessage = true) {
      this.loading = true;

      try {
        const { data, error } = await apiService.patch({ endpoint: `users/switch-project/${id}` });

        if (error) {
          throw new Error(error);
        }

        if (this.currentProject?.id !== id) {
          const response = await this.fetchCurrentProject(id);

          if (response.error) {
            throw new Error(response.error);
          }
        }

        const projectName =
          (this.currentProject?.name || "").length > 25
            ? this.currentProject?.name.slice(0, 25) + "..."
            : this.currentProject?.name;

        successMessage &&
          toast.success({
            title: "Uhuul!",
            message: `Agora você está no projeto ${projectName}!`,
            duration: 3000
          });

        await Promise.all([updateStatus(), this.includeProject()]);

        userStatus.value.lastActiveProject.id = id;

        return { data };
      } catch (error) {
        errorHandlerService.handleError(error);

        return { error };
      } finally {
        this.loading = false;
      }
    },
    async includeProject() {
      // NOTE: Essa função responsável por adicionar o projeto atual na lista de projetos caso ele já não esteja lá
      const currentId = userStatus.value?.lastActiveProject.id;
      if (!this.projects) return;
      if (!this.projects?.find((p: Project) => p.id === currentId)) {
        const { error, data } = await apiService.get<Project>({ endpoint: `projects/${currentId}` });
        if (!data || error) {
          return;
        }
        this.projects.push(data);
      }
    },
    async archiveProject(id: string, _deleted = false) {
      const { error } = await apiService.patch({ endpoint: `projects/${id}/archive` });
      return error;
    },
    async loadMoreProjects(filter: Record<keyof ProjectFilter, string | number | boolean>) {
      // NOTE: a funrna um booleana que representa se há mais intens para carregar ou não, basicamente
      // se o resultado da busca tiver length 0 isso quer dizer que não há mais itens para carregar
      const query = formatQueryParams(filter);
      const teamId = userStatus.value?.activeTeam?.id;
      const url = getProjectFetchUrlByUserRole(
        `projects?teamId=${teamId}&simplified=true&amount=${MAX_PROJECTS_PER_FETCH}&${query}`
      );

      if (!teamId) return;
      const { data, error } = await apiService.get<ProjectFetchResponse>({ endpoint: url });

      if (!data || error) {
        return false;
      }
      let result = data.projects || [];
      result = result.filter((p1: Project) => !this.projects.find((p2: Project) => p2.id === p1.id));
      this.projects = [...this.projects, ...result];
      return true;
    },
    async createFolder(projectId: string, folderName: string, parentFolderId: string | null = null) {
      const { error } = await apiService.post({
        endpoint: `projects/${projectId}/folders`,
        body: {
          name: folderName,
          parentFolderId
        }
      });
      if (!!error) {
        return error;
      }
      this.fetchProjects();
      return error;
    },
    async updateUsers(projectId: string, user: User, action: "add" | "remove" = "add") {
      const teamStore = useTeamStore();
      const url = `teams/${teamStore.currentTeam?.id}/users/${user.email}`;

      const body: Record<string, unknown> = {
        projectId,
        removeFromProject: action !== "add"
      };

      const { error } = await apiService.patch({
        endpoint: url,
        body
      });
      return error;
    },
    getProject(id: string): Project | undefined {
      return this.projects.find((project: Project) => project.id === id);
    },
    async duplicateProject(id: string) {
      const { error } = await apiService.post({ endpoint: `projects/${id}/duplicate` });
      return error;
    },
    async fetchAudiences(simplified = false) {
      const creationStore = useProjectCreationStore(pinia);

      const currProject = this.current;

      if (currProject?.audiences.length) {
        const teamId = userStatus.value.activeTeam.id;
        this.loadingAudiences = true;

        const res = await creationStore.getProjectAudiences(teamId, currProject.audiences, simplified);

        if (res) {
          this.audiences = res.map(audience => ({ ...audience, checked: false }));
          creationStore.audience = res;
        }

        this.loadingAudiences = false;
      } else {
        this.audiences = [];
      }
    }
  },
  getters: {
    current(state: ProjectStoreStateType): Project | undefined {
      return state.currentProject;
    },
    projectsListing(state: ProjectStoreStateType): Project[] | undefined {
      return state.projects;
    }
  }
});

const getProjectFetchUrlByUserRole = (url: string) => {
  const userId = userStatus.value?.id;
  const userFilter = ["admin", "manager"].includes(userStatus.value?.activeTeam?.role) ? "" : `&users=${userId}`;

  return url + userFilter;
};

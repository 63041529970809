// Services & Helpers
import { apiService } from "@/core/services/apiService";

// Composables
import { useUser } from "./useUser";

type ActiveCampaignTagName = "Individual" | "Business 1" | "Business 2" | "Carrinho abandonado" | string;

export const useActiveCampaignTag = () => {
  const { userStatus } = useUser();

  const createTagRequest = async (tagName: ActiveCampaignTagName, contact: Record<string, string>) => {
    const response = await apiService.post({
      endpoint: "/active-campaign/contacts/tags",
      body: {
        tagName,
        contact
      }
    });

    return response.error;
  };

  const subscribeToList = async (listId: number, contact: Record<string, string>) => {
    const response = await apiService.post({
      endpoint: `/active-campaign/contacts/lists/${listId}`,
      body: {
        contact
      }
    });

    return response.error;
  };

  const triggerTag = async (tagName: ActiveCampaignTagName, listId?: number) => {
    const contact: Record<string, string> = {
      email: userStatus.value?.email || "",
      firstName: userStatus.value?.firstName || "",
      phone: userStatus.value?.phoneNumber || ""
    };

    if (userStatus.value?.lastName) {
      contact.lastName = userStatus.value?.lastName;
    }

    await createTagRequest(tagName, contact);

    if (listId) subscribeToList(listId, contact);
  };

  return { triggerTag };
};

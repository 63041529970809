// Assets
import edit from "@/assets/icons/writer/edit.svg?url";
import conclusion from "@/assets/icons/writer/conslusion.svg?url";
import paragraph from "@/assets/icons/writer/paragraph.svg?url";
import catRedesSociaisIcon from "@/assets/svg/icons/models/cat_redes_sociais.svg?url";
import legendaPostsIcon from "@/assets/svg/icons/models/legenda_posts.svg?url";
import bioInstagramIcon from "@/assets/svg/icons/models/bio_instagram.svg?url";
import cronogramaRedesSociaisIcon from "@/assets/svg/icons/models/cronograma_redes_sociais.svg?url";
import linkedinIcon from "@/assets/svg/icons/models/linkedin.svg?url";
import postTwitterIcon from "@/assets/svg/icons/models/post_twitter.svg?url";
import ideiasCriativoImagemIcon from "@/assets/svg/icons/models/ideias_criativo_imagem.svg?url";
import catAnuncioOnlineIcon from "@/assets/svg/icons/models/cat_anuncio_online.svg?url";
import googleAdsIcon from "@/assets/svg/icons/models/google_ads.svg?url";
import youtubeAdsIcon from "@/assets/svg/icons/models/youtube_ads.svg?url";
import facebookAdsIcon from "@/assets/svg/icons/models/facebook_ads.svg?url";
import ideiasCriativoVideoIcon from "@/assets/svg/icons/models/ideias_criativo_video.svg?url";
import catEmailIcon from "@/assets/svg/icons/models/cat_email.svg?url";
import emailVendasIcon from "@/assets/svg/icons/models/email_vendas.svg?url";
import emailBoasVindasIcon from "@/assets/svg/icons/models/email_boas_vindas.svg?url";
import emailProspeccaoIcon from "@/assets/svg/icons/models/email_prospeccao.svg?url";
import emailCarrinhoAbandonadoIcon from "@/assets/svg/icons/models/email_carrinho_abandonado.svg?url";
import emailUpsellIcon from "@/assets/svg/icons/models/email_upsell.svg?url";
import catMelhoradorConteudosIcon from "@/assets/svg/icons/models/cat_melhorador_conteudos.svg?url";
import resumirTextoIcon from "@/assets/svg/icons/models/resumir_texto.svg?url";
import continuarTextoIcon from "@/assets/svg/icons/models/continuar_texto.svg?url";
import catFormulasCopyIcon from "@/assets/svg/icons/models/cat_formulas_copy.svg?url";
import frameworkAidaIcon from "@/assets/svg/icons/models/framework_aida.svg?url";
import frameworkBabIcon from "@/assets/svg/icons/models/framework_bab.svg?url";
import frameworkPasIcon from "@/assets/svg/icons/models/framework_pas.svg?url";
import frameworkPpppIcon from "@/assets/svg/icons/models/framework_pppp.svg?url";
import catProdutosIcon from "@/assets/svg/icons/models/cat_produtos.svg?url";
import descricaoDoProdutoIcon from "@/assets/svg/icons/models/descricao_do_produto.svg?url";
import nomeProdutoIcon from "@/assets/svg/icons/models/nome_produto.svg?url";
import avaliacoesProdutoIcon from "@/assets/svg/icons/models/avaliacoes_produtos.svg?url";
import beneficiosProdutoIcon from "@/assets/svg/icons/models/beneficios_produto.svg?url";
import catVideosIcon from "@/assets/svg/icons/models/cat_videos.svg?url";
import descricaoVideoIcon from "@/assets/svg/icons/models/descricao_video.svg?url";
import scriptVideoIcon from "@/assets/svg/icons/models/script_video.svg?url";
import tituloParaVideoIcon from "@/assets/svg/icons/models/titulo_para_video.svg?url";
import catArtigosSeoIcon from "@/assets/svg/icons/models/cat_artigos_seo.svg?url";
import secaoBlogpostIcon from "@/assets/svg/icons/models/secao_blogpost.svg?url";
import fundamentosSeoBlogpostIcon from "@/assets/svg/icons/models/fundamentos_seo_blogpost.svg?url";
import extratorPalavrasChaveIcon from "@/assets/svg/icons/models/extrator_palavras_chave.svg?url";
import catPaginasIcon from "@/assets/svg/icons/models/cat_paginas.svg?url";
import titulosIcon from "@/assets/svg/icons/models/titulos.svg?url";
import sloganIcon from "@/assets/svg/icons/models/slogan.svg?url";
import topicosPersuasivosIcon from "@/assets/svg/icons/models/topicos_persuasivos.svg?url";
import catProspeccaoVendasIcon from "@/assets/svg/icons/models/cat_prospeccao_vendas.svg?url";
import vendasWhatsappIcon from "@/assets/svg/icons/models/vendas_whatsapp.svg?url";
import coldCallIcon from "@/assets/svg/icons/models/cold_call.svg?url";
import catInsightsIcon from "@/assets/svg/icons/models/cat_insights.svg?url";
import planoMarketingIcon from "@/assets/svg/icons/models/plano_marketing.svg?url";
import ideiasRidiculasMarketingIcon from "@/assets/svg/icons/models/ideias_ridiculas_marketing.svg?url";
import catChatInteligenteIcon from "@/assets/svg/icons/models/cat_chat_inteligente.svg?url";
import catCopydocsIcon from "@/assets/svg/icons/models/cat_copydocs.svg?url";
import catGeradorImagensIcon from "@/assets/svg/icons/models/cat_gerador_imagem.svg?url";
import carrosselIcon from "@/assets/svg/icons/models/carrossel.svg?url";
import storiesCronogramIcon from "@/assets/svg/icons/models/stories_cronogram.svg?url";
import catLandingPageIcon from "@/assets/svg/icons/models/cat_landing_page.svg?url";
import landingEmBreveIcon from "@/assets/svg/icons/models/landing_em_breve.svg?url";
import landingVendasIcon from "@/assets/svg/icons/models/landing_vendas.svg?url";
import landingLeadsIcon from "@/assets/svg/icons/models/landing_leads.svg?url";
import landingObrigadoIcon from "@/assets/svg/icons/models/landing_obrigado.svg?url";
import landingProdutoIcon from "@/assets/svg/icons/models/landing_produto.svg?url";

// Types
import { NewModelCategory, ModelsGroup, ModelSchema } from "./../../@types/models";

// NOTE: sempre que alterar algo nesse arquivo, altere a versão para forçar
// a atualização do cache dos modelos no navegador
export const configVersion = "1.3.1";

const postCaptionModel: ModelSchema = {
  id: "9",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Legendas de posts",
  description: "Crie as melhores legendas para as suas postagens.",
  longDescription:
    "Crie as melhores legendas para as suas postagens<br/><br/><b>Dúvidas sobre como preencher?</b><br/>Forneça o máximo de detalhes possíveis.<br/>Utilize frases concisas e que possuam sentido semântico.<br/>Escreva no mínimo 10 palavras para ter um resultado melhor.<br/>Varie as palavras para aumentar a criatividade do robozinho.<br/>Insira a ação que deseja que sua audiência realize na postagem.",
  route: "post-captions-v2",
  useGoogle: true,
  usePersona: true,
  useReferences: true,
  svgUrl: legendaPostsIcon,
  fontIcon: "bi-chat-square-text",
  status: "active",
  category: "Redes sociais",
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Redes Sociais", "Branding"],
  positions: ["Social Media", "Afiliado", "Outro"],
  videoUrl: "https://player.vimeo.com/video/821686236?h=75a68d1900",
  inputSchema: [
    {
      type: "extra",
      field: "select",
      label: "Qual a plataforma do post?",
      tooltip: "Selecione a plataforma da sua postagem.",
      placeholder: "Selecione a plataforma da sua postagem",
      required: true,
      options: [
        { value: "instagram", label: "Instagram" },
        { value: "facebook", label: "Facebook" },
        { value: "linkedin", label: "LinkedIn" },
        { value: "x/twitter", label: "X / Twitter" }
      ]
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "Qual o assunto do post?",
      placeholder: "Exemplo: Apresentar a tecnologia dos simuladores exclusivos do treinamento para Astro Star",
      tooltip:
        "Forneça o máximo de detalhes possíveis sobre o assunto do post. Utilize frases concisas e que possuam sentido semântico."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Liste os diferenciais com bullets points e emojis",
      tooltip:
        "Adicione mais informações para guiar o conteúdo da legenda e / ou objetivo do post. Exemplo: informativo/educativo, geração de leads, vendas promover evento, engajamento (curtidas, comentários e compartilhamentos), aumentar a conscientização da marca."
    }
  ]
};

const socialMediaTimelineModel: ModelSchema = {
  id: "44",
  fields: {
    length: false,
    tone: false,
    language: true
  },
  title: "Cronograma de Redes Sociais",
  description: "Crie o planejamento estratégico, com diversos assuntos e formatos para as redes sociais.",
  longDescription:
    "Mantenha um fluxo constante de conteúdos relevantes e interessantes para seus seguidores no Instagram.",
  route: "cron-social-v2",
  useGoogle: true,
  usePersona: true,
  useReferences: true,
  svgUrl: cronogramaRedesSociaisIcon,
  fontIcon: "bi-instagram",
  status: "active",
  category: "Redes sociais",
  numOutputs: 3,
  outputsNum: 3,
  filters: ["Redes Sociais", "Insights", "Branding"],
  positions: ["Social Media", "Outro"],
  addToReference: false,
  inputSchema: [
    {
      type: "extra",
      field: "select",
      label: "Plataforma",
      required: true,
      placeholder: "Selecione a plataforma da sua postagem",
      tooltip: "Selecione a plataforma da sua postagem.",
      options: [
        { value: "instagram", label: "Instagram" },
        { value: "facebook", label: "Facebook" },
        { value: "linkedin", label: "LinkedIn" },
        { value: "TikTok", label: "TikTok" },
        { value: "x/twitter", label: "X / Twitter" }
      ]
    },
    {
      type: "name",
      field: "select",
      label: "Números de semanas",
      required: true,
      placeholder: "Selecione a plataforma da sua postagem",
      tooltip: "Selecione o número de semanas que o cronograma deve ter.",
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" }
      ]
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      label: "Assuntos do(s) perfil(s)",
      required: true,
      placeholder: "Exemplo: Espaço, tecnologia em foguetes, cursos exclusivos para formação",
      tooltip: "Informe sobre o(s) assunto(s) do perfil."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Os conteúdos em vídeos de no máximo 30 segundos",
      tooltip: "Adicione mais informações para guiar as estratégias e conteúdos da para a criação do cronograma."
    }
  ]
};

const postsIdeasModel: ModelSchema = {
  id: "199",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "Ideias de posts",
  description: "Gere uma copy atrativa e descrição para o designer criar a peça para sua rede social.",
  longDescription:
    "O modelo pode para o Instagram gera uma copy atrativa e descrição do designer indicado para a peça de acordo com a descrição informada. Caso tenha alguma informação chave, divulgação ou dado a ser inserido no post adicione o mesmo na descrição.",
  route: "post-instagram-v2",
  useGoogle: true,
  useReferences: true,
  svgUrl: ideiasCriativoImagemIcon,
  fontIcon: "bi-instagram",
  status: "active",
  category: "Redes sociais",
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Redes Sociais", "Branding", "Insights"],
  inputSchema: [
    {
      type: "extra",
      field: "select",
      label: "Plataforma",
      required: true,
      placeholder: "Selecione a plataforma da sua postagem",
      tooltip: "Selecione a plataforma da sua sua postagem.",
      options: [
        { value: "instagram", label: "Instagram" },
        { value: "facebook", label: "Facebook" },
        { value: "linkedin", label: "LinkedIn" },
        { value: "TikTok", label: "TikTok" },
        { value: "x/twitter", label: "X / Twitter" }
      ]
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "Assuntos do(s) perfil(s)",
      placeholder: "Exemplo:  Espaço, tecnologia em foguetes, cursos exclusivos para formação",
      tooltip: "Bloqueio criativo? Informe sobre o(s) assunto(s) do perfil para ter ideiais de posts."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Conteúdos que tenham ligação com consumo de água no planeta",
      tooltip: "Adicione mais informações para guiar as estratégias e conteúdos da para a criação do cronograma."
    }
  ]
};

const imageCreativesIdeasModel: ModelSchema = {
  id: "54",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "Ideias para criativo de imagem",
  description: "Desbloqueie a criatividade com copy atrativa e descrição detalhada do design focado em tráfego pago.",
  longDescription:
    "O modelo criativo imagem gera uma copy persuasiva e descrição do designer indicado para a peça. Caso tenha alguma oferta especial não deixe de inserir na descrição. Lembre-se que sucesso de um anúncio não está somente na copy, mas também no designer, segmentação e oferta.",
  route: "copy-creative-v2",
  useGoogle: true,
  svgUrl: ideiasCriativoImagemIcon,
  fontIcon: "bi-image-alt",
  status: "most-used",
  category: "Ads",
  useReferences: true,
  outputsNum: 1,
  addToReference: false,
  filters: ["Anúncios", "Branding"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "O que é anunciado",
      placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 2.500,00 por mês.",
      tooltip:
        "Descreva sobre o produto, serviço ou marca se trata o seu anúncio. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star"
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2029",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const sellsEmailModel: ModelSchema = {
  id: "33",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "E-mail de vendas",
  shortTitle: "Vendas",
  description: "Crie texto com objetivo de convencer o destinatário a tomar uma decisão de compra.",
  longDescription:
    "O modelo “E-mail de Vendas” é ideal para quando precisar comunicar um novo produto, ou uma oferta por e-mail para a sua base. Com ele, você terá acesso a e-mails persuasivos preenchendo algumas informações.",
  route: "email-vendas-v2",
  useGoogle: false,
  useReferences: true,
  svgUrl: emailVendasIcon,
  fontIcon: "bi-envelope-open-fill",
  status: "active",
  category: "E-mail",
  addToReference: false,
  filters: ["Vendas", "E-mails"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "O que está à venda",
      placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
      maxLength: 1500,
      required: true,
      tooltip:
        "Informe sobre o produto ou serviço que é citado no e-mail, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
    },
    {
      type: "extra",
      field: "textarea",
      label: "Oferta única",
      placeholder: "Exemplo: 15% OFF: R$ 2.500,00/ mês",
      maxLength: 500,
      required: true,
      tooltip:
        "Caso tenha, cite a oferta apresentada no e-mail, condições únicas. Exemplo: valor, formas de pagamento, data limie, lotes."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2029",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const prospectEmailModel: ModelSchema = {
  id: "35",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "E-mail de Prospecção",
  shortTitle: "Prospecção",
  description: "Crie texto para iniciar um relacionamento com potenciais clientes com comunicação mais sutil.",
  longDescription:
    "O modelo “E-mail de Prospecção” é ideal para buscar ativamente novos clientes. Crie um texto interessante e persuasivo para enviar no primeiro contato a um possível cliente.",
  route: "email-cold-v2",
  useGoogle: false,
  svgUrl: emailProspeccaoIcon,
  fontIcon: "bi-suit-heart-fill",
  status: "active",
  category: "E-mail",
  useReferences: true,
  addToReference: false,
  filters: ["Vendas", "E-mails"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "O que está sendo oferecido",
      placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
      maxLength: 1500,
      required: true,
      tooltip:
        "Informe sobre o produto ou serviço que é citado no e-mail, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
    },
    {
      type: "extra",
      field: "textarea",
      label: "Oferta única",
      placeholder: "Exemplo: 15% OFF: R$ 2.500,00/ mês",
      maxLength: 500,
      required: true,
      tooltip:
        "Caso tenha, cite a oferta apresentada no e-mail, condições únicas. Exemplo: valor, formas de pagamento, data limie, lotes."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2029",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const sloganModel: ModelSchema = {
  id: "129",
  fields: {
    length: false,
    tone: false,
    language: true
  },
  title: "Slogan",
  description: "Crie uma frase de impacto para o seu negócio.",
  longDescription:
    "O modelo “Slogan” cria frases de efeito que resumem as características de um produto/serviço e sejam de fácil memorização.",
  route: "slogan-v2",
  useGoogle: false,
  svgUrl: sloganIcon,
  fontIcon: "bi-braces",
  status: "active",
  category: "Marca",
  useReferences: true,
  addToReference: false,
  filters: ["Insights", "Branding"],
  inputSchema: [
    {
      type: "name",
      field: "input",
      label: "Nome do produto ou marca",
      required: true,
      placeholder: "Exemplo: Treinamento Astro Star",
      tooltip: "Qual o nome do seu produto, serviço ou marca?",
      maxLength: 150
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      label: "Promessa principal",
      placeholder: "Exemplo: Treinar pessoas sem experiência a conseguir virar astronauta em 6 meses.",
      tooltip:
        "Descreva de forma clara a promessa principal do produto, serviço ou marca. Exemplo: Benefícios, diferenciais, dor resolvida e etc"
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Explore ser o único no mundo que entrega isso.",
      tooltip: "Adicione mais informações para compor o conteúdo da página."
    }
  ]
};

const emailFunnelModel: ModelSchema = {
  id: "43",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Funil de e-mails",
  description: "E-mails eficientes e persuasivos de acordo com a etapa do funil de venda/prospecção que precisar.",
  longDescription: "E-mails eficientes e persuasivos de acordo com a etapa do funil de venda/prospecção que precisar.",
  route: "email-prospeccao-2-v2",
  useGoogle: false,
  svgUrl: emailUpsellIcon,
  fontIcon: "bi-envelope-at",
  status: "active",
  category: "Prospecção/Vendas",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Vendas", "E-mails"],
  inputSchema: [
    {
      type: "extra",
      field: "select",
      label: "Etapa do funil",
      tooltip: "Informe o nome do produto ou serviço que vai divulgar no funil de e-mails",
      placeholder: "Selecione a etapa do funil deste e-mail",
      required: true,
      default: "Awareness",
      options: [
        { value: "Awareness", label: "Conscientização" },
        { value: "Consideration", label: "Consideração" },
        { value: "Decision", label: "Decisão" },
        { value: "Retention", label: "Retenção" },
        { value: "Reactivation", label: "Reativação" }
      ]
    },
    {
      type: "name",
      field: "input",
      maxLength: 150,
      required: true,
      label: "Nome da empresa",
      placeholder: "Exemplo: Astro Star",
      tooltip: "Informe o nome da empresa que está enviando o email."
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "Assunto do e-mail",
      placeholder: "Exemplo: Apresentar a tecnologia dos simuladores exclusivos do treinamento para Astro Star",
      tooltip:
        "Forneça o máximo de detalhes possíveis sobre o assunto do post. Utilize frases concisas e que possuam sentido semântico. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abertura para inscrição no treinamento."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Liste os diferenciais com bullets points e emojis",
      tooltip:
        "Adicione mais informações para guiar o conteúdo da legenda e / ou objetivo do post. Exemplo: informativo/educativo, geração de leads, vendas promover evento, engajamento (curtidas, comentários e compartilhamentos), aumentar a conscientização da marca"
    }
  ]
};

const headlineModel: ModelSchema = {
  id: "26",
  fields: {
    length: false,
    tone: false,
    language: true
  },
  title: "Títulos",
  description: "Headlines matadoras para seu produto ou serviço.",
  longDescription:
    "O modelo “Títulos” permite que você crie chamadas ou títulos persuasivos para diversos objetivos, como: headlines para página de vendas, títulos para e-mails ou chamadas para criativos.",
  route: "h1-v2",
  useGoogle: false,
  svgUrl: titulosIcon,
  fontIcon: "bi-card-heading",
  status: "most-used",
  category: "Produto",
  videoUrl: "https://player.vimeo.com/video/827973636?h=d43263f759",
  useReferences: true,
  addToReference: false,
  filters: ["Insights", "Vendas"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "Assunto do conteúdo",
      placeholder: "Exemplo: Astro Star",
      tooltip:
        "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star.",
      maxLength: 500,
      info: {
        title: "Qual o nome do produto ou serviço?",
        subtitle: "Dúvidas sobre como preencher?",
        list: [
          "<strong>Empresa:</strong> nome da empresa que está anunciando o produto/serviço",
          "<strong>Produto:</strong> nome do produto que está sendo vendido",
          "<strong>Serviço:</strong> qual serviço está sendo oferecido"
        ],
        examples: ["Baseworks - Gerador de Textos Inteligentes", "Corretor Postural UltraMax", "Assistência Automotiva"]
      }
    },
    {
      type: "instructions",
      field: "textarea",
      label: "Instruções adicionais",
      placeholder: "Exemplo: Explorar nome de astronautas ou personagens famosos",
      tooltip:
        "Adicione mais informações ou estratégias para guiar o resultado. Exemplo: Dicas de como treinar a respiração e sono em ambientes e posições desfavoráveis OU o título deve usar nome de astronautas famosos",
      maxLength: 1500
    }
  ]
};

const persuasiveTopicsModel: ModelSchema = {
  id: "27",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "Tópicos Persuasivos",
  description: "Crie tópicos que tornem o seu produto mais atraente.",
  longDescription:
    "O modelo “Tópicos Persuasivos” te ajuda a criar os principais benefícios para o seu produto ou serviço e os lista em tópicos.",
  route: "bullets-v2",
  useGoogle: false,
  svgUrl: topicosPersuasivosIcon,
  fontIcon: "bi-list-ul",
  status: "active",
  category: "Produto",
  videoUrl: "https://player.vimeo.com/video/827973685?h=f3866bdec7",
  useReferences: true,
  addToReference: false,
  filters: ["Anúncios", "Vendas"],
  positions: ["Social Media", "Designer", "Redator / Copywriter"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "Assunto do conteúdo",
      placeholder: "Exemplo: Astro Star",
      required: true,
      maxLength: 500,
      tooltip:
        "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
    },
    {
      type: "instructions",
      field: "textarea",
      label: "Instruções adicionais",
      placeholder: "Exemplo: Explorar nome de astronautas ou personagens famosos",
      tooltip:
        "Adicione mais informações ou estratégias para guiar o resultado. Exemplo: Dicas de como treinar a respiração e sono em ambientes e posições desfavoráveis OU o título deve usar nome de astronautas famosos",
      maxLength: 1500
    }
  ]
};

const videoCreativesIdeasModel: ModelSchema = {
  id: "50",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "Ideias para criativo de vídeo",
  description: "Desbloqueie a criatividade com roteiro completo (copy e sugestão de edição) para o criativo.",
  longDescription:
    "O modelo criativo vídeo gera um roteiro junto à sugestão de edição para te ajudar a  criar vídeos mais envolventes para os criativos. Caso tenha alguma oferta especial não deixe de inserir na descrição.<br/>Lembre-se que sucesso de um anúncio não está somente na copy, mas também no designer, segmentação e oferta.",
  route: "video-creative-v2",
  useGoogle: true,
  svgUrl: ideiasCriativoVideoIcon,
  fontIcon: "bi-camera-video",
  status: "most-used",
  category: "Ads",
  useReferences: true,
  outputsNum: 1,
  addToReference: false,
  filters: ["Anúncios", "Branding", "Vídeos"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "O que é anunciado",
      placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 2.500,00 por mês. ",
      tooltip:
        "Descreva sobre o produto, serviço ou marca se trata o seu anúncio. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star"
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2029",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const videoScriptModel: ModelSchema = {
  id: "30",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Script para vídeo",
  description: "Crie um roteiro completo com falas e sequência apropriada para seus vídeos.",
  longDescription:
    "O modelo “Script de Vídeo” te ajuda a criar vídeos mais envolventes, sugerindo a melhor ordem de temas a serem abordados.",
  route: "video-script-v2",
  useGoogle: true,
  svgUrl: scriptVideoIcon,
  fontIcon: "bi-camera-video",
  status: "active",
  category: "Youtube",
  useReferences: true,
  addToReference: false,
  filters: ["Vídeos"],
  positions: ["Designer"],
  inputSchema: [
    {
      type: "extra",
      field: "select",
      label: "Objetivo do plano",
      required: true,
      tooltip:
        "Selecione o formato para ter o roteiro do seu vídeo.<br/>Formatos e duração: reels, story, tiktok e short<br/>Tamanho pequeno - duração mínima de 18 e máxima de 30 segundos<br/>Médio- duração máxima de 60 segundos<br/>Longo- duração máxima de 200 segundos<br/>Formato: vídeo padrão youtube<br/>Tamanho pequeno - duração mínima de 4 e máxima de 6 minutos<br/>Médio- duração mínima de 8 e máxima de 14 minutos<br/>Longo- duração mínima de 20 e máxima de 40 minutos",
      options: [
        { value: "Reels - Instagram", label: "Reels - Instagram" },
        { value: "Short - Youtube", label: "Short - Youtube" },
        { value: "Story - Instagram", label: "Story - Instagram" },
        { value: "TikTok", label: "TikTok" },
        { value: "Default - Youtube", label: "Padrão - Youtube" }
      ],
      placeholder: "Exemplo: Crie um roteiro completo com falas e sequência apropriada para seus vídeos."
    },
    {
      type: "description",
      field: "textarea",
      label: "Assunto",
      placeholder: "Exemplo: Apresentação da tecnologia dos simuladores exclusivos para Astro Star",
      tooltip: "Forneça o máximo de detalhes possíveis para a criação do conteúdo do post.",
      required: true,
      maxLength: 500
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: O vídeo deve ter no máximo 3 minutos. ",
      tooltip: "Adicione mais informações ou estratégias para guiar o resultado."
    }
  ]
};

const productBenefitsModel: ModelSchema = {
  id: "8",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Benefícios do produto",
  description: "Liste os principais benefícios do produto de forma interessante.",
  longDescription:
    "O modelo “Benefícios do Produto” destaca os benefícios e vantagens do produto para os consumidores. Fundamental para a construção de uma estratégia de marketing eficaz, destacando a relevância do produto para os consumidores e aumentando suas chances de vendas.",
  route: "beneficios-v2",
  useGoogle: false,
  svgUrl: beneficiosProdutoIcon,
  fontIcon: "bi-list-check",
  status: "active",
  category: "Produto",
  videoUrl: "https://player.vimeo.com/video/827973268?h=c52f16ae4f",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Anúncios", "Vendas", "E-commerce"],
  positions: ["Social Media", "Afiliado", "Outro"],
  inputSchema: [
    {
      type: "name",
      field: "input",
      maxLength: 150,
      required: true,
      label: "Nome do produto",
      placeholder: "Exemplo: Treinamento Astro Star",
      tooltip:
        "Escreva o nome do produto que será divulgado na landing page. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star."
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      label: "Principais características do produto",
      placeholder: "Exemplo: Treinamento exclusivo para astronautas, Feito por astronaustas experientes",
      required: true,
      tooltip:
        "Descreva de forma clara as principais características do produto que será divulgado na página. Benefícios, diferenciais, dor resolvida, garantia e etc."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Usada tecnologia de ponta e simuladores.",
      tooltip: "Adicione mais informações para compor/guiar o conteúdo para o produto."
    }
  ]
};

const productDescModel: ModelSchema = {
  id: "5",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Descrição de produto",
  description: "Crie texto para descrever seu produto da melhor forma.",
  longDescription:
    "O modelo “Descrição de produto” cria uma descrição detalhada e atrativa do produto, destacando suas características, especificações e diferenciais. É essencial para a apresentação de um produto em lojas online, catálogos e outras formas de mídia.",
  route: "desc-v2",
  useGoogle: true,
  svgUrl: descricaoDoProdutoIcon,
  fontIcon: "bi-text-left",
  status: "most-used",
  category: "Produto",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  filters: ["Anúncios", "Vendas", "E-commerce"],
  positions: ["Gestor de tráfego", "Afiliado"],
  addToReference: false,
  videoUrl: "https://player.vimeo.com/video/821686066?h=094b9e41bd",
  inputSchema: [
    {
      type: "name",
      field: "input",
      required: true,
      maxLength: 150,
      label: "Nome do produto",
      placeholder: "Exemplo: Treinamento Astro Star",
      tooltip: "Escreva o nome do produto que será divulgado na landing page."
    },
    {
      type: "description",
      field: "textarea",
      required: true,
      maxLength: 500,
      label: "Principais características do produto",
      tooltip:
        "Descreva de forma clara as principais características do produto que será divulgado na página. Benefícios, diferenciais, dor resolvida, garantia e etc",
      placeholder: "Exemplo: Treinamento exclusivo para astronautas, Feito por astronaustas experientes"
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      tooltip: "Adicione mais informações para compor/guiar o conteúdo para o produto.",
      placeholder: "Exemplo: Duração de 6 meses. Usada tecnologia de ponta e simuladores."
    }
  ]
};

const productReviewModel: ModelSchema = {
  id: "7",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Avaliações de produto",
  description: "Crie texto para apresentar comentários como prova social.",
  longDescription:
    "O modelo “Avaliação de Produto” apresenta opiniões dos clientes de uma forma atrativa e persuasiva, enfatizando os pontos positivos e destacando as provas sociais. Cria comentários que destacam as vantagens e satisfações experimentadas com o produto. Assim é possível aumentar a confiança do público-alvo e incentivar a compra.",
  route: "review-v2",
  useGoogle: false,
  svgUrl: avaliacoesProdutoIcon,
  fontIcon: "bi-star-half",
  status: "active",
  category: "Produto",
  videoUrl: "https://player.vimeo.com/video/827973332?h=bad643a683",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Insights", "Vendas", "E-commerce"],
  inputSchema: [
    {
      type: "name",
      field: "input",
      maxLength: 150,
      label: "Nome do produto",
      required: true,
      placeholder: "Exemplo: Treinamento Astro Star",
      tooltip: "Escreva o nome do produto que será divulgado na landing page"
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      label: "Principais caractecrísticas do produto",
      required: true,
      placeholder: "Exemplo: Treinamento exclusivo para astronautas, Feito por astronaustas experientes",
      tooltip:
        "Descreva de forma clara as principais características do produto que será divulgado na página. Benefícios, diferenciais, dor resolvida, garantia e etc"
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Usada tecnologia de ponta e simuladores.",
      tooltip: "Adicione mais informações para compor/guiar o conteúdo para o produto."
    }
  ]
};

const welcomeEmailModel: ModelSchema = {
  id: "34",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "E-mail de boas-vindas",
  shortTitle: "Boas-vindas",
  description: "Crie texto para dar boas-vindas para seus novos leads.",
  longDescription:
    "O modelo “Boas-Vindas” gera textos para poder manter contato com quem acabou de conhecer e se cadastrar na sua base. Dica extra: Este é o primeiro contato que você faz com seus novos leads, aproveite para criar conexão a partir de um texto interessante. O e-mail de Boas-Vindas é uma boa chance de se engajar com o público.",
  route: "email-welcome-v2",
  useGoogle: false,
  svgUrl: emailBoasVindasIcon,
  fontIcon: "bi-flower1",
  status: "active",
  category: "E-mail",
  useReferences: true,
  addToReference: false,
  filters: ["Vendas", "E-mails"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "O que o contato teve acesso",
      placeholder: "Exemplo: Astro Star",
      tooltip:
        "Informe o nome da marca/negócio ou produto/serviço a qual o destinatário se cadastrou ou comprou.  Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2029",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const emailCartModel: ModelSchema = {
  id: "36",
  fields: {
    length: false,
    tone: true,
    language: true
  },
  title: "E-mail de carrinho abandonado",
  description: "Crie texto para converter a compra abandonada pelo seu lead.",
  longDescription:
    "O modelo “Carrinho Abandonado” é ideal para gerar textos que precisa enviar aos compradores que saem do site sem concluir o processo de pagamento. Dica extra: Automatize seus e-mails, programe pelo menos 3 e-mails para o funil de Carrinho abandonado.- Esse é um dos e-mails mais importantes para aumentar a conversão de um negócio.",
  route: "email-cart-v2",
  useGoogle: false,
  svgUrl: emailCarrinhoAbandonadoIcon,
  fontIcon: "bi-cart-x",
  status: "active",
  category: "E-mail",
  useReferences: true,
  numOutputs: 3,
  addToReference: false,
  filters: ["Vendas", "E-mails", "E-commerce"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      maxLength: 500,
      required: true,
      label: "O que está à venda",
      placeholder:
        "Treinamento para astronautas, por R$ 2.500,00/ mês. Instrutores experientes e infraestrutura de ponta.",
      tooltip:
        "Informe o nome do produto ou serviço que vai divulgar no e-mail. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star."
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Oferecer 15% off, explorar diferenciais",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const aidaModel: ModelSchema = {
  id: "1",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Framework AIDA",
  description: "Crie textos mais persuasivos no modelo de Atenção, Interesse, Desejo e Ação.",
  longDescription:
    "O modelo “AIDA” segue o conceito que se baseia no processo de compra do cliente - desde o primeiro contato até a conversão.<br/><br/><b>A</b> - Atenção: Ganhe a atenção do público-alvo<br/><b>I</b> - Interesse: Desperte o interesse a partir da  curiosidade, dúvidas ou problemas que o produto ou serviço possa resolver<br/><b>D</b> - Desejo: Instigue o desejo pelo seu produto/serviço, mostre as vantagens.<br/><b>A</b> - Ação: Convença o público a tomar a ação, seja comprar, cadastrar, ou qualquer outra.",
  route: "aida-v2",
  useGoogle: true,
  svgUrl: frameworkAidaIcon,
  fontIcon: "bi-exclamation-octagon",
  status: "most-used",
  category: "Framework",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  filters: ["Anúncios", "Vendas"],
  positions: ["Gestor de tráfego", "Produtor / Co-produtor", "Redator / Copywriter", "Outro"],
  addToReference: false,
  videoUrl: "https://player.vimeo.com/video/821685754?h=7c3be04795",
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "Assunto",
      placeholder: "Exemplo: Astro Star",
      tooltip:
        "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star.",
      maxLength: 500,
      required: true
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Usar regras de fórmulas de física",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const babModel: ModelSchema = {
  id: "2",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Framework BAB",
  description: "Crie texto apresentando cenários:  Before/Antes - After/Depois - Bridge/Ponte.",
  longDescription:
    "O modelo “BAB” segue o conceito de apontar uma dor que impacta o leitor, em seguida como seria muito melhor sem ela e finaliza apresentando a forma de alcançar a solução do problema.<br/><br/><b>B-</b> Antes: Apresente o cenário tocando na dor do público<br/><b>A-</b> Depois: Simule o cenário com a ausência da dor<br/><b>B-</b> Ponte: Revele a forma de alcançar o melhor cenário<br/>",
  route: "bab-v2",
  useGoogle: true,
  svgUrl: frameworkBabIcon,
  fontIcon: "bi-journal-text",
  status: "active",
  category: "Framework",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  videoUrl: "https://player.vimeo.com/video/821685844?h=a0fc873948",
  filters: ["Vendas"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "Assunto",
      placeholder: "Exemplo: Astro Star",
      tooltip:
        "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star.",
      maxLength: 500,
      required: true
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Usar regras de fórmulas de física",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const pasModel: ModelSchema = {
  id: "3",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Framework PAS",
  description: "Crie texto apresentando um Problema e entregue uma Solução.",
  longDescription:
    "O modelo “PAS” segue o conceito de encontrar o(s) problema(s) de seus clientes e apresentar sua oferta de forma persuasiva. Assim, você cria um elo com o público-alvo, despertando a sensação de que o seu produto ou serviço é exatamente aquilo que eles buscam.<br/><br/><b>P-</b> Problema: Apresente de forma objetiva o problema<br/><b>A-</b> Agitação: Descrever o problema e como afeta negativamente a vida do lead<br/><b>S-</b> Solução: Apresente seu produto/serviço como a solução para os problemas",
  route: "pas-v2",
  useGoogle: true,
  svgUrl: frameworkPasIcon,
  fontIcon: "bi-check-all",
  status: "most-used",
  category: "Framework",
  useReferences: true,
  addToReference: false,
  videoUrl: "https://player.vimeo.com/video/821686138?h=30e5eb1abe",
  filters: ["Anúncios", "Vendas"],
  positions: ["Redator / Copywriter"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "Assunto",
      placeholder: "Exemplo: Astro Star",
      tooltip:
        "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star.",
      maxLength: 500,
      required: true
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Usar regras de fórmulas de física",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const ppppModel: ModelSchema = {
  id: "4",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Framework PPPP",
  description: "Crie texto apresentando Proposta, Prova, Preço, Push/Ação.",
  longDescription:
    "O modelo “PPPP” segue o conceito de apresentar a proposta, provar o seu valor, pontuar o preço e pressionar o público a tomar uma ação.",
  route: "pppp-v2",
  useGoogle: true,
  svgUrl: frameworkPpppIcon,
  fontIcon: "bi-journal-text",
  status: "active",
  category: "Framework",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  videoUrl: "https://player.vimeo.com/video/821686311?h=247be6fb9d",
  filters: ["Vendas"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      label: "Assunto",
      placeholder: "Exemplo: Astro Star",
      tooltip:
        "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star.",
      maxLength: 500,
      required: true
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: Usar regras de fórmulas de física",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
    }
  ]
};

const whatsappSellModel: ModelSchema = {
  id: "412",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Vendas pelo Whatsapp",
  description: "Script de mensagens para venda/prospecção no Whatsapp.",
  longDescription:
    "Script de mensagens para venda/prospecção no Whatsapp com base nas etapas do funil de vendas:<ul><li>Conscientização;</li><li>Interesse e avaliação;</li><li>Decisão;</li><li>Ação;</li><li>Retenção</li></ul>",
  route: "whatsapp-prospeccao-v2",
  useGoogle: true,
  svgUrl: vendasWhatsappIcon,
  fontIcon: "bi-suit-heart-fill",
  status: "active",
  category: "Prospecção/Vendas",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Vendas"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      maxLength: 1000,
      label: "Descreva o seu negócio",
      placeholder: "Exemplo: 50h de treinamento exclusivo para adaptação da vida em Marte.",
      info: {
        title: "Descrição do produto/serviço",
        subtitle: "Dúvidas sobre como preencher?",
        list: [
          "Forneça o máximo de detalhes possíveis.",
          "Utilize frases concisas e que possuam sentido semântico.",
          "Escreva no mínimo 10 palavras para ter um resultado melhor.",
          "Varie as palavras para aumentar a criatividade do robozinho. ",
          "Insira a ação que deseja que sua audiência realize na postagem."
        ]
      }
    },
    {
      type: "target",
      field: "input",
      label: "Para quem deseja vender?",
      placeholder: "Exemplo: Pessoas de classe alta, que possuem interesse no espaço e vida extraterrestre",
      maxLength: 150
    }
  ]
};

const coldCallModel: ModelSchema = {
  id: "42",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Cold Call",
  description: "Script de falas para venda/prospecção em ligações.",
  longDescription:
    "Script de falas para venda/prospecção em ligações com base nas etapas do funil de vendas:<ul><li>Conscientização;</li><li>Interesse e avaliação;</li><li>Decisão;</li><li>Ação;</li><li>Retenção</li></ul>",
  route: "ligacao-prospeccao-v2",
  useGoogle: true,
  svgUrl: coldCallIcon,
  fontIcon: "bi-telephone-outbound",
  status: "active",
  category: "Prospecção/Vendas",
  useReferences: true,
  numOutputs: 3,
  outputsNum: 3,
  addToReference: false,
  filters: ["Vendas"],
  inputSchema: [
    {
      type: "description",
      field: "textarea",
      maxLength: 1000,
      label: "Descreva o seu negócio",
      placeholder: "Exemplo: 50h de treinamento exclusivo para adaptação da vida em Marte.",
      info: {
        title: "Descrição do produto/serviço",
        subtitle: "Dúvidas sobre como preencher?",
        list: [
          "Forneça o máximo de detalhes possíveis.",
          "Utilize frases concisas e que possuam sentido semântico.",
          "Escreva no mínimo 10 palavras para ter um resultado melhor.",
          "Varie as palavras para aumentar a criatividade do robozinho. ",
          "Insira a ação que deseja que sua audiência realize na postagem."
        ]
      }
    },
    {
      type: "target",
      field: "input",
      label: "Para quem deseja vender?",
      placeholder: "Exemplo: Pessoas de classe alta, que possuem interesse no espaço e vida extraterrestre",
      maxLength: 150
    }
  ]
};

const mktPlanIdeasModel: ModelSchema = {
  id: "39",
  fields: {
    length: false,
    tone: false,
    language: true
  },
  title: "Plano de Marketing",
  description: "Ideias para elaborar uma estratégia de marketing.",
  longDescription: "",
  route: "ideas-plano-mkt-v2",
  useGoogle: true,
  svgUrl: planoMarketingIcon,
  fontIcon: "bi-cloud-drizzle",
  status: "active",
  category: "Criativo",
  filters: ["Insights", "Branding"],
  positions: ["Gestor de tráfego", "Produtor / Co-produtor", "Outro"],
  useReferences: true,
  addToReference: false,
  inputSchema: [
    {
      label: "A marca/produto/serviço",
      placeholder: "Exemplo: Primeiro treinamento intensivo para formar astronautas dedicado a todo o público.",
      type: "description",
      field: "input",
      required: true,
      tooltip:
        "Informe sobre a marca, produto ou serviço que será gerado o plano de marketing. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star.",
      maxLength: 500,
      info: {
        title: "Para qual a marca/produto/serviço?",
        subtitle: "Dúvidas sobre como preencher?",
        list: ["Insira o nicho da marca, ou função do produto/serviço"],
        examples: ["Baseworks - Gerador de Textos focado em marketing com inteligência artificial"]
      }
    },
    {
      type: "instructions",
      field: "textarea",
      maxLength: 1500,
      label: "Instruções adicionais",
      placeholder: "Exemplo: O plano deve ser focado na américa do Sul. O treinamento inicia em 2029 na Flórida.",
      tooltip:
        "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras.",
      info: {
        title: "Descrição da sua empresa",
        subtitle: "Dúvidas sobre como preencher?",
        list: [
          "Fale sobre o objetivo do vídeo.",
          "Evite frases e termos muito longos.",
          "Forneça o máximo de detalhes possíveis.",
          "Varie as palavras para aumentar a criatividade do robozinho."
        ]
      }
    },
    {
      type: "extra",
      field: "select",
      label: "Objetivo do plano",
      options: [
        { value: "Raising brand awareness", label: "Aumentar consciência da marca" },
        { value: "Sales growth", label: "Crescimento de Vendas" },
        { value: "Improving Customer Loyalty", label: "Melhorar Fidelidade do Cliente" },
        { value: "Market expansion", label: "Expansão de Mercado" },
        { value: "Maximize ROI", label: "Maximizar ROI" },
        { value: "Lead generation", label: "Geração de Leads" }
      ],
      placeholder: "Selecione o objetivo do plano de marketing"
    }
  ]
};

const mktRidiculousIdeasModel: ModelSchema = {
  id: "curto",
  fields: {
    length: true,
    tone: true,
    language: true
  },
  title: "Ideias ridículas de Marketing",
  description: "Acha que só tem ideias rídiculas? Ridículo é não fazer nada!",
  longDescription: "",
  route: "ideias-ridiculous-v2",
  useGoogle: true,
  svgUrl: ideiasRidiculasMarketingIcon,
  fontIcon: "bi-cloud-drizzle",
  status: "active",
  category: "Criativo",
  useReferences: true,
  addToReference: false,
  filters: ["Insights"],
  positions: ["Designer", "Outro"],
  inputSchema: [
    {
      label: "Para qual a marca/produto/serviço?",
      placeholder: "Insira o nicho da marca, ou função do produto/serviço",
      type: "name",
      field: "input",
      maxLength: 150,
      info: {
        title: "Para qual a marca/produto/serviço?",
        subtitle: "Dúvidas sobre como preencher?",
        list: ["Insira o nicho da marca, ou função do produto/serviço"],
        examples: ["Baseworks - Gerador de Textos focado em marketing com inteligência artificial"]
      }
    },
    {
      type: "description",
      field: "textarea",
      maxLength: 650,
      label: "Descreva a sua empresa ou produto",
      placeholder: "Escreva informações sobre sua empresa, o que faz, seu público-alvo, etc",
      info: {
        title: "Descrição da sua empresa",
        subtitle: "Dúvidas sobre como preencher?",
        list: [
          "Fale sobre o objetivo do vídeo.",
          "Evite frases e termos muito longos.",
          "Forneça o máximo de detalhes possíveis.",
          "Varie as palavras para aumentar a criatividade do robozinho."
        ]
      }
    }
  ]
};

export const modelsConfig: Partial<Record<NewModelCategory, ModelsGroup>> = {
  "rede-social": {
    id: 3,
    category: "rede-social",
    useReferences: true,
    name: "Redes Sociais",
    description: "Ideias e textos envolventes para as redes sociais.",
    svgUrl: catRedesSociaisIcon,
    usefull: ["Conteúdos p/ redes sociais", "Conteúdo orgânico", "Lançamento"],
    models: [
      {
        ...postCaptionModel
      },
      {
        id: "10",
        fields: {
          length: false,
          tone: false,
          language: true
        },
        title: "Bio de Instagram",
        description: "Crie frases para deixar o perfil do Instagram mais interessante.",
        longDescription:
          "Crie frases para deixar o perfil do Instagram mais interessante<br/><br/><b>Dúvidas sobre como preencher?</b><br/>Informe diretamente sobre o(s) assunto(s) do perfil.<br/>Utilize termos concisos.",
        route: "bio-insta-v2",
        useGoogle: false,
        useReferences: true,
        svgUrl: bioInstagramIcon,
        fontIcon: "bi-instagram",
        status: "active",
        category: "Redes sociais",
        numOutputs: 3,
        outputsNum: 3,
        addToReference: false,
        filters: ["Redes Sociais", "Branding", "Insights"],
        videoUrl: "https://player.vimeo.com/video/821685996?h=66784e8476",
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Assunto do perfil",
            placeholder: "Exemplo: Astro Star- Maior empresa de cursos e treinamentos para astronautas",
            tooltip: "Informe diretamente sobre o(s) assunto(s) do perfil."
          }
        ]
      },
      {
        ...socialMediaTimelineModel
      },
      {
        id: "45",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Post LinkedIn",
        shortTitle: "LinkedIn",
        description: "Crie postagens e descrições de cargos de acordo com o seu perfil ou do seu negócio.",
        longDescription: "Divulgue vagas e crie os melhores posts para o LinkedIn.",
        route: "linkedin-v2",
        useGoogle: false,
        useReferences: true,
        svgUrl: linkedinIcon,
        fontIcon: "bi-linkedin",
        status: "active",
        category: "Redes sociais",
        numOutputs: 3,
        outputsNum: 3,
        addToReference: false,
        filters: ["Redes Sociais", "Branding"],
        inputSchema: [
          {
            type: "name",
            field: "textarea",
            maxLength: 150,
            required: true,
            label: "Objetivo",
            placeholder: "Exemplo: Divulgar pesquisa com equipe de profissionais renomados",
            tooltip:
              "Informe qual o objetivo do post para guiar a criação do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abrir lista de espera do Astro Star."
          },
          {
            type: "extra",
            field: "select",
            label: "Tipo",
            tooltip:
              "Selecione o tipo de post que você deseja criar. Cada tipo de post tem um propósito diferente e formatos específicos.                                                                                Texto simples: Atualizações simples, opiniões, reflexões, insights ou informações importantes. Artigo: Publicações longas e detalhadas que aparecem na seção de artigos do perfil. Enquete: Criação de enquetes para obter feedback ou iniciar discussões. Deve começar com uma introdução interessante. Divulgação de evento: Convites para webinars, conferências ou meetups. Inclua todas as informações do evento. Notificação: Notificações sobre novas habilidades, mudanças de emprego ou certificações conquistadas. Anúncio de Vaga: Publicações de oportunidades de emprego para atrair candidatos qualificados. Outro: Especifique outro tipo de publicação.",
            placeholder: "Selecione a plataforma da sua postagem",
            required: true,
            options: [
              { value: "Plain text", label: "Texto simples" },
              { value: "Articles", label: "Artigos" },
              { value: "Poll", label: "Enquete" },
              { value: "Event announcement", label: "Divulgação de evento" },
              { value: "Notification", label: "Notificação" },
              { value: "Vacancy announcement", label: "Anúncio de vaga" },
              { value: "Other", label: "Outro" }
            ]
          },
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Assunto do post",
            placeholder: "Exemplo: Promover um webinar inédito com 2 grandes astronautas",
            tooltip:
              "Informe o assunto principal do post para a elaboração. Divulgue vagas e crie os melhores posts para o LinkedIn. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abertura para inscrição no treinamento."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder:
              "Exemplo: Dia 05 de dezembro. Vagas limitadas. Fazer a inscrição e aguardar o contato da nossa equipe.",
            tooltip:
              "Adicione mais informações para guiar o conteúdo. Exemplo: definições de abordagens, estratégias, objetivos (aumentar engajamento, gerar leads, cmpartilhar conhecimento, promover produto ou serviço, fortalecer a marca)"
          }
        ]
      },
      {
        id: "47",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Post Twitter / X / Threads",
        shortTitle: "Twitter / X",
        description: "Crie tweets envolventes sobre qualquer assunto.",
        longDescription: "Crie tweets interessantes para manter a rede do passarinho atualizada.",
        route: "twitter-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: postTwitterIcon,
        fontIcon: "bi-twitter",
        status: "active",
        category: "Redes sociais",
        numOutputs: 3,
        outputsNum: 3,
        addToReference: false,
        filters: ["Redes Sociais", "Insights"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Assunto do post",
            placeholder: "Exemplo: Promover um webinar inédito com 2 grandes astronautas",
            tooltip:
              "Crie conteúdo interessantes para manter a rede atualizada. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abertura para inscrição no treinamento."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Vagas limitadas. Garantir a inscrição",
            tooltip:
              "Adicione mais informações para guiar o conteúdo. Exemplo: definições de abordagens, estratégias, tamanho. "
          }
        ]
      },
      {
        ...postsIdeasModel
      },
      {
        id: "444",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Post Carrossel",
        shortTitle: "Carrossel",
        description: "Crie conteúdo versátil e eficaz para engajamento com narrativa para os seus posts.",
        route: "carrossel-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: true,
        useReferences: true,
        svgUrl: carrosselIcon,
        fontIcon: "bi-caret-right-square",
        status: "active",
        category: "Redes sociais",
        numOutputs: 3,
        outputsNum: 3,
        filters: ["Redes Sociais"],
        positions: ["Social Media", "Outro"],
        newUntil: new Date("08-14-2024"),
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            label: "Assunto",
            required: true,
            placeholder: "Exemplo: Apresentar a tecnologia dos simuladores exclusivos do treinamento para Astro Star",
            tooltip:
              "Forneça o máximo de detalhes possíveis para a criação do conteúdo do post. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abertura para inscrição no treinamento."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Usar fenômenos e fórmulas de física pra reforçar autoridade",
            tooltip:
              "Adicione mais informações para guiar o conteúdo. Exemplo: definições de abordagens, estratégias, tamanho."
          }
        ]
      },
      {
        id: "445",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Cronograma de Stories",
        description: "Organize e planeje as publicações nos stories e mantenha a constância de conteúdo.",
        route: "stories-cronogram-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: true,
        useReferences: true,
        svgUrl: storiesCronogramIcon,
        fontIcon: "bi-instagram",
        status: "active",
        category: "Redes sociais",
        numOutputs: 1,
        outputsNum: 1,
        addToReference: false,
        newUntil: new Date("08-14-2024"),
        filters: ["Redes Sociais"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Assuntos do perfil",
            placeholder: "Exemplo: Espaço, tecnologia em foguetes, cursos exclusivos para formação.",
            tooltip: "Informe sobre o(s) assunto(s) do perfil."
          },
          {
            type: "extra",
            field: "select",
            label: "Números de semanas",
            tooltip: "Selecione o número de semanas que o cronograma deve ter.",
            placeholder: "Selecione a plataforma da sua postagem",
            required: true,
            default: "2",
            options: [
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" }
            ]
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Os conteúdos em vídeos de no máximo 30 segundos",
            tooltip: "Adicione mais informações para guiar as estratégias e conteúdos da para a criação do cronograma."
          }
        ]
      }
    ]
  },
  ads: {
    id: 4,
    category: "ads",
    useReferences: true,
    name: "Anúncio Online",
    description: "Crie textos mais persuasivos para os seus anúncios online.",
    svgUrl: catAnuncioOnlineIcon,
    usefull: ["Performance", "Anúncio", "Vendas", "Lançamento"],
    models: [
      {
        id: "11",
        fields: {
          length: true,
          tone: false,
          language: true
        },
        title: "Google Ads - Pesquisa",
        description: "Crie títulos e descrições persuasivas de anúncios para Google Ads.",
        longDescription: "",
        route: "gads2-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: googleAdsIcon,
        fontIcon: "bi-google",
        status: "active",
        category: "Ads",
        videoUrl: "https://player.vimeo.com/video/827973425?h=f6512bd7d9",
        outputsNum: 3,
        addToReference: false,
        filters: ["Anúncios", "Vendas"],
        positions: ["Gestor de tráfego", "Afiliado"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Sobre o que é o anúncio?",
            placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 4.500,00 por mês",
            tooltip: "Descreva sobre o produto, serviço ou marca se trata o seu anúncio."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo da página. Exemplo: Forma de pagamento, local, testemunhos, facilidades de compra."
          }
        ]
      },
      {
        id: "41",
        fields: {
          length: true,
          tone: false,
          language: true
        },
        title: "Google Ads - PMAX",
        description: "Crie todos os títulos e descrições persuasivos para campanha Pmax.",
        longDescription: "",
        route: "pmax-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: googleAdsIcon,
        fontIcon: "bi-google",
        status: "active",
        category: "Ads",
        outputsNum: 1,
        numOutputs: 1,
        addToReference: false,
        filters: ["Anúncios", "Vendas"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Sobre o que é o anúncio?",
            placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 4.500,00 por mês",
            tooltip: "Descreva sobre o produto, serviço ou marca se trata o seu anúncio."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo da página. Exemplo: Forma de pagamento, local, testemunhos, facilidades de compra."
          }
        ]
      },
      {
        id: "48",
        fields: {
          length: true,
          tone: false,
          language: true
        },
        title: "YouTube Ads",
        description: "Crie todos os títulos e descrições adequadas pra campanha do Youtube.",
        longDescription: "Crie todos os títulos e descrições pra sua campanha do Youtube",
        route: "ytads-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: youtubeAdsIcon,
        fontIcon: "bi-youtube",
        status: "active",
        category: "Ads",
        outputsNum: 1,
        numOutputs: 1,
        addToReference: false,
        filters: ["Anúncios", "Vendas"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 1500,
            required: true,
            label: "Sobre o que é o anúncio?",
            placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 4.500,00 por mês",
            tooltip: "Descreva sobre o produto, serviço ou marca se trata o seu anúncio."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo da página. Exemplo: Forma de pagamento, local, testemunhos, facilidades de compra."
          }
        ]
      },
      {
        id: "482",
        fields: {
          length: true,
          tone: false,
          language: true
        },
        title: "Google Ads - Discovery",
        description: "Crie todos os títulos e descrições pra sua campanha de Discovery.",
        longDescription: "Crie todos os títulos e descrições pra sua campanha de Discovery",
        route: "discovery-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: googleAdsIcon,
        fontIcon: "bi-google",
        status: "active",
        category: "Ads",
        outputsNum: 1,
        numOutputs: 1,
        addToReference: false,
        filters: ["Anúncios", "Vendas"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Sobre o que é o anúncio?",
            placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 4.500,00 por mês",
            tooltip: "Descreva sobre o produto, serviço ou marca se trata o seu anúncio."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo da página. Exemplo: Forma de pagamento, local, testemunhos, facilidades de compra."
          }
        ]
      },
      {
        id: "12",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Facebook / Meta Ads",
        shortTitle: "Facebook / Meta",
        description: "Crie textos para aumentar a conversão do seu anúncio no Meta Ads.",
        longDescription: "",
        route: "fbads-v2",
        useGoogle: false,
        usePersona: true,
        useReferences: true,
        svgUrl: facebookAdsIcon,
        fontIcon: "bi-facebook",
        status: "most-used",
        category: "Ads",
        outputsNum: 3,
        filters: ["Anúncios", "Vendas", "Redes Sociais"],
        positions: ["Gestor de tráfego", "Produtor / Co-produtor", "Afiliado"],
        addToReference: false,
        videoUrl: "https://player.vimeo.com/video/821686410?h=b0d669b0b1",
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Sobre o que é o anúncio?",
            placeholder: "Exemplo: Treinamento Astro Star. Curso para astronautas, por R$ 4.500,00 por mês",
            tooltip: "Descreva sobre o produto, serviço ou marca se trata o seu anúncio."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo da página. Exemplo: Forma de pagamento, local, testemunhos, facilidades de compra."
          }
        ]
      },
      {
        id: "46",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "LinkedIn Ads",
        description: "Crie títulos e descrições mais persuasivos para anúncios do LinkedIn.",
        longDescription: " Crie títulos e descrições mais persuasivos para os anúncios no LinkedIn",
        route: "linkedin-ads-v2",
        useGoogle: true,
        svgUrl: linkedinIcon,
        fontIcon: "bi-linkedin",
        status: "most-used",
        category: "Ads",
        useReferences: true,
        outputsNum: 3,
        addToReference: false,
        filters: ["Anúncios", "Vendas"],
        inputSchema: [
          {
            type: "name",
            field: "input",
            maxLength: 150,
            label: "Qual o nome do produto ou serviço?",
            placeholder: "Insira apenas o nome do seu produto ou serviço",
            info: {
              title: "Qual o nome do produto ou serviço?",
              subtitle: "Dúvidas sobre como preencher?",
              list: [
                "<strong>Empresa:</strong> nome da empresa que está anunciando o produto/serviço",
                "<strong>Produto:</strong> nome do produto que está sendo vendido",
                "<strong>Serviço:</strong> qual serviço está sendo oferecido"
              ],
              examples: [
                "Baseworks - Gerador de Textos Inteligentes",
                "Ar condicionado central",
                "Assistência Automotiva"
              ]
            }
          },
          {
            type: "description",
            field: "textarea",
            maxLength: 1000,
            label: "Sobre o seu produto ou serviço",
            placeholder: "Descreva seu produto ou serviço em poucas palavras",
            info: {
              title: "Descrição do produto ou serviço",
              subtitle: "Dúvidas sobre como preencher?",
              list: [
                "Forneça o máximo de detalhes possíveis.",
                "Utilize frases concisas e que possuam sentido semântico.",
                "Escreva no mínimo 10 palavras para ter um resultado melhor.",
                "Varie as palavras para aumentar a criatividade do robozinho."
              ]
            }
          }
        ]
      },
      {
        ...videoCreativesIdeasModel
      },
      {
        ...imageCreativesIdeasModel
      }
    ]
  },
  email: {
    id: 9,
    category: "email",
    useReferences: true,
    name: "E-mails",
    description: "Gere o corpo e assunto dos seus e-mails.",
    svgUrl: catEmailIcon,
    usefull: ["Vendas", "Prospecção", "Relacionamento com cliente", "Lançamento"],
    models: [
      {
        ...sellsEmailModel
      },
      {
        ...welcomeEmailModel
      },
      {
        ...prospectEmailModel
      },
      {
        ...emailCartModel
      },
      {
        ...emailFunnelModel
      },
      {
        id: "448",
        fields: {
          length: true,
          tone: false,
          language: true
        },
        title: "E-mail de Upsell",
        shortTitle: "Oferta de Upsell",
        description: "Crie um texto para aumentar o valor médio das compras de clientes.",
        route: "email-upsell-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: true,
        useReferences: true,
        svgUrl: emailUpsellIcon,
        fontIcon: "bi-envelope-plus",
        status: "active",
        category: "E-mail",
        addToReference: false,
        filters: ["Vendas", "E-commerce", "E-mails"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está sendo ofertado?",
            placeholder: "Exemplo: Mentoria individual sobre estudos aeroespaciais",
            tooltip: "Informe o nome do produto ou serviço que vai oferecer ao cliente."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Com 2 professores referência em Harvard, 3 calls de até 75 minutos",
            tooltip:
              "Adicione mais informações para compor o conteúdo da página. Exemplo: Data, local, testemunhos, informações de contato."
          }
        ]
      },
      {
        id: "451",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "E-mail sob demanda",
        shortTitle: "Livre sob demanda",
        description: "Dê as orientações e tenha o e-mail que deseja.",
        route: "email-demand-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: true,
        useReferences: true,
        svgUrl: emailBoasVindasIcon,
        fontIcon: "bi-envelope",
        status: "active",
        category: "E-mail",
        addToReference: false,
        filters: ["E-mails"],
        newUntil: new Date("08-14-2024"),
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 150,
            required: true,
            label: "Objetivo",
            placeholder:
              "Exemplo: Divulgar inscrição para participar de pesquisa com equipe de profissionais renomados",
            tooltip:
              "Informe qual o objetivo e do que se trata o email para guiar a criação do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abrir lista de espera do Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Focar na urgência para a inscrição",
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "446",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Assuntos de E-mail",
        shortTitle: "Ideias de assuntos",
        description: "Crie assuntos interessantes para que os e-mails sejam abertos e lidos.",
        route: "email-ideas-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailProspeccaoIcon,
        fontIcon: "bi-envelope-paper",
        status: "active",
        category: "E-mail",
        addToReference: false,
        newUntil: new Date("08-14-2024"),
        filters: ["E-mails"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Produto ou serviço",
            placeholder: "Exemplo: Treinamento para astronautas com vagas limitadas",
            tooltip:
              "Informe sobre o produto ou serviço que é citado no e-mail. Não é necessário informar o nome do produto/ serviço, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Explorar as dores do público",
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "447",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "E-mail de confirmação de pagamento",
        shortTitle: "Confirmação de Pagamento",
        description: "Crie um texto para comunicar e garantir ao cliente que sua transação foi processada com sucesso.",
        route: "email-payment-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailVendasIcon,
        fontIcon: "bi-envelope-check",
        status: "active",
        category: "E-mail",
        addToReference: false,
        newUntil: new Date("08-14-2024"),
        filters: ["Vendas", "E-commerce", "E-mails"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que foi comprado",
            placeholder: "Exemplo: Treinamento para astronautas, por R$ 2.500,00/ mês.",
            tooltip:
              "Informe sobre o produto ou serviço que é citado no e-mail, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Plano com 15% OFF",
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "450",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "E-mail de Pesquisa de Satisfação",
        shortTitle: "Pesquisa de Satisfação",
        description: "Colete feedback dos clientes sobre seus produtos, serviços, ou experiência de compra.",
        route: "email-satisfaction-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailProspeccaoIcon,
        fontIcon: "bi-search-heart",
        status: "active",
        category: "E-mail",
        addToReference: false,
        newUntil: new Date("08-14-2024"),
        filters: ["E-mails", "Branding"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que o cliente consumiu/comprou",
            placeholder:
              "Exemplo: 2 mentorias individuais que o cliente adquiriu para elevar o conhecimento aeroespacial",
            tooltip:
              "Inclua referências específicas aos produtos ou serviços utilizados pelo cliente para tornar a pesquisa mais relevante e direcionada. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações e instruções complementares para compor e personalizar a pesquisa. Exemplo: Data, local, incentivos para a resposta, informações de contato."
          }
        ]
      },
      {
        id: "449",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "E-mail de Reengajamento",
        shortTitle: "Reengajamento",
        description: "Crie e-mails com incentivos ou informações para reengajar a base.",
        route: "email-reengagement-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailProspeccaoIcon,
        fontIcon: "bi-envelope-paper-heart",
        status: "active",
        category: "E-mail",
        addToReference: false,
        soon: false,
        newUntil: new Date("09-14-2024"),
        filters: ["E-mails", "Branding"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está sendo oferecido",
            placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
            tooltip:
              "Informe sobre o produto ou serviço que é citado no e-mail, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      }
    ]
  },
  text: {
    id: 5,
    category: "text",
    useReferences: true,
    name: "Melhorador de Conteúdos",
    description: "Precisa melhorar seu texto? Essa opção é ideal pra você.",
    svgUrl: catMelhoradorConteudosIcon,
    usefull: ["Ajustes de texto"],
    models: [
      {
        id: "13",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Melhorar/Reescrever texto",
        description: "Reescreva um conteúdo para torná-lo mais interessante, criativo e envolvente.",
        longDescription:
          "O modelo “Melhorar texto“ analisa conteúdo, identifica problemas de escrita e propõe alterações para melhorar o conteúdo e permite que você mantenha o controle sobre o resultado final.",
        route: "improver-v2",
        useGoogle: false,
        useReferences: true,
        svgUrl: resumirTextoIcon,
        fontIcon: "bi-chat-heart",
        status: "most-used",
        category: "Textos",
        videoUrl: "https://player.vimeo.com/video/827973576?h=9a40e6484b",
        numOutputs: 1,
        outputsNum: 1,
        positions: ["Produtor / Co-produtor", "Social Media", "Redator / Copywriter"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 3000,
            required: true,
            label: "Texto a ser melhorado",
            placeholder: "Insira aqui o texto que deseja melhorar",
            tooltip: "Informe o texto que deseja melhorar."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Utilização de termos mais formais em tom tecnológico",
            tooltip: "Descreva instruções que devem ser condiderada para a melhoria do texto"
          }
        ]
      },
      {
        id: "14",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Resumir texto",
        description: "Tenha ajuda para resumir conteúdos muito extensos.",
        longDescription:
          "O modelo “Resumo de Texto“ entende e sintetiza grandes quantidades de texto em um resumo conciso e convincente, onde você pode personalizar os resumos para refletir o tom e o estilo da sua preferência.",
        route: "summary-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: resumirTextoIcon,
        fontIcon: "bi-arrows-angle-contract",
        status: "active",
        category: "Textos",
        addToReference: false,
        outputsNum: 1,
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            label: "Texto a ser resumido",
            placeholder: "Insira aqui o texto que deseja resumir",
            maxLength: 3000,
            required: true,
            tooltip: "Informe o texto que deseja resumir."
          }
        ]
      },
      {
        id: "15",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Continuar texto",
        description: "Informe uma frase/parágrafo e deixe que a I.A. escreva a continuação.",
        longDescription:
          "O modelo “Continue o Texto” usa o texto que você inseriu inicialmente para continuar o conteúdo de forma autêntica e, assim, gerar textos mais longos de forma rápida.",
        route: "continue-v2",
        useGoogle: true,
        useReferences: true,
        svgUrl: continuarTextoIcon,
        fontIcon: "bi-chat-dots",
        status: "active",
        category: "Textos",
        positions: ["Designer"],
        numOutputs: 1,
        outputsNum: 1,
        addToReference: false,
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 3000,
            required: true,
            label: "Texto a ser continuado",
            placeholder: "EInsira aqui o texto que deseja continuar",
            tooltip: "Informe o texto que deseja que seja continuado"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Utilização de termos técnicos",
            tooltip: "Descreva instruções que devem ser condiderada para criar a continuação."
          }
        ]
      }
    ]
  },
  copy: {
    id: 1,
    name: "Fórmulas de Copy",
    category: "copy",
    useReferences: true,
    description: "Crie textos a partir de modelos para atingir seu público e aumentar a conversão.",
    svgUrl: catFormulasCopyIcon,
    usefull: ["Vendas", "Desbloquear a criatividade", "Página de vendas", "Anúncios"],
    models: [
      {
        ...aidaModel
      },
      {
        ...babModel
      },
      {
        ...pasModel
      },
      {
        ...ppppModel
      }
    ]
  },
  product: {
    id: 2,
    name: "Produtos",
    category: "product",
    useReferences: true,
    description: "Crie textos ou nome a partir de modelos base para elevar o nível do produto.",
    svgUrl: catProdutosIcon,
    usefull: ["E-commerce", "Dropshipping", "Página de vendas", "Loja física", "Atendimento"],
    models: [
      {
        ...productDescModel
      },
      {
        id: "6",
        fields: {
          length: false,
          tone: false,
          language: true
        },
        title: "Nome para produto",
        description: "Tenha ideias de nomes interessantes para o produto.",
        longDescription:
          "O modelo “Nome do Produto” auxilia na definição do nome ideal para o seu produto/serviço. O nome impacta diretamente nos resultados de marketing, por isso, é fundamental escolher um nome criativo e memorável que chame a atenção dos consumidores.",
        route: "name-v2",
        useGoogle: false,
        svgUrl: nomeProdutoIcon,
        fontIcon: "bi-textarea-t",
        status: "active",
        category: "Produto",
        useReferences: true,
        numOutputs: 3,
        outputsNum: 3,
        addToReference: false,
        videoUrl: "https://player.vimeo.com/video/821685918?h=3945db8ecd",
        filters: ["E-commerce"],
        inputSchema: [
          {
            type: "name",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Principal promessa do produto",
            placeholder: "Exemplo: Aprender fundamentos aeroespaciais e se tornar um astronauta.",
            tooltip: "Explique a promessa do produto, ou seja, que dor ele deve resolver."
          },
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Principais características do produto",
            placeholder: "Exemplo: Treinamento exclusivo para astronautas, Feito por astronaustas experientes",
            tooltip:
              "Descreva de forma clara as principais características do produto que será divulgado na página. Benefícios, diferenciais, dor resolvida, garantia e etc"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            required: true,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Usada tecnologia de ponta e simuladores.",
            tooltip: "Adicione mais informações para compor/guiar o conteúdo para o produto."
          }
        ]
      },
      {
        ...productReviewModel
      },
      {
        ...productBenefitsModel
      }
    ]
  },
  video: {
    id: 8,
    category: "video",
    useReferences: true,
    name: "Vídeos",
    description: "Crie a descrição, gere ideias ou títulos incríveis para os seus vídeos.",
    svgUrl: catVideosIcon,
    usefull: ["Youtube", "Conteúdos p/ redes sociais", "Criativos em vídeo"],
    models: [
      {
        id: "29",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Descrição para vídeo",
        description: "Crie as melhores descrições para vídeos nas redes sociais.",
        longDescription:
          "O modelo “Descrição de Vídeo” permite que você crie as melhores descrições para os seus vídeos nas redes sociais, como o YouTube.",
        route: "video-desc-v2",
        useGoogle: false,
        svgUrl: descricaoVideoIcon,
        fontIcon: "bi-youtube",
        status: "active",
        category: "Youtube",
        useReferences: true,
        addToReference: false,
        filters: ["Vídeos"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            label: "Assunto do vídeo",
            required: true,
            tooltip:
              "Descreva sobre o assunto/tema do vídeo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abertura para inscrição no treinamento.",
            placeholder: "Exemplo: Apresentação da tecnologia dos simuladores exclusivos para Astro Star",
            info: {
              title: "Palavras-chave relacionadas",
              subtitle: "Dúvidas sobre como preencher?",
              list: [
                "Fale sobre o objetivo do vídeo.",
                "Evite frases e termos muito longos.",
                "Forneça o máximo de detalhes possíveis.",
                "Varie as palavras para aumentar a criatividade do robozinho."
              ]
            }
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Usar termos técnicos e fórmulas de física",
            tooltip: "Adicione mais informações ou estratégias para guiar o resultado."
          }
        ]
      },
      {
        ...videoScriptModel
      },
      {
        id: "31",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Título para vídeo",
        description: "Crie títulos interessantes e criativos para seus vídeos.",
        longDescription:
          "O modelo “Título de Vídeo” te ajuda a criar títulos interessantes e criativos para seus vídeos.",
        route: "video-title-v2",
        useGoogle: false,
        svgUrl: tituloParaVideoIcon,
        fontIcon: "bi-youtube",
        status: "active",
        category: "Youtube",
        useReferences: true,
        addToReference: false,
        filters: ["Anúncios", "Branding", "Insights", "Vídeos"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            label: "Assunto do vídeo",
            required: true,
            tooltip:
              "Descreva sobre o assunto/tema do vídeo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Abertura para inscrição no treinamento.",
            placeholder: "Exemplo: Apresentação da tecnologia dos simuladores exclusivos para Astro Star",
            info: {
              title: "Palavras-chave relacionadas",
              subtitle: "Dúvidas sobre como preencher?",
              list: [
                "Fale sobre o objetivo do vídeo.",
                "Evite frases e termos muito longos.",
                "Forneça o máximo de detalhes possíveis.",
                "Varie as palavras para aumentar a criatividade do robozinho."
              ]
            }
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Usar termos técnicos e fórmulas de física",
            tooltip:
              "Adicione mais informações ou estratégias para guiar o resultado. Exemplo: Objetivo, tamanho, viés..."
          }
        ]
      }
    ]
  },
  article: {
    id: 6,
    category: "article",
    useReferences: true,
    name: "Artigos e SEO",
    description: "Crie artigos completos para seu blog de artigos e notícias.",
    svgUrl: catArtigosSeoIcon,
    usefull: ["Blog", "Artigos", "SEO"],
    models: [
      {
        id: "21",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Seção de blog post",
        description: "Crie conteúdo de qualidade e interessante para o artigo.",
        longDescription:
          "O modelo “Seção de blog post” é ideal para complementar tópicos e subtítulos que você já tenha.",
        route: "writer-blog-post-v3",
        useGoogle: true,
        svgUrl: secaoBlogpostIcon,
        fontIcon: "bi-chat-left-quote",
        status: "active",
        category: "Blog",
        useReferences: true,
        numOutputs: 3,
        addToReference: false,
        filters: ["Blog", "Insights"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            label: "Assunto",
            placeholder: "Exemplo: Astro Star",
            required: true,
            maxLength: 500,
            tooltip:
              "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            label: "Instruções adicionais",
            placeholder: "Exemplo: Usar regras de fórmulas de física",
            maxLength: 1500,
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "22",
        fields: {
          length: false,
          tone: false,
          language: true
        },
        title: "Ideias de Títulos de blog post",
        description: "Crie títulos interessantes e criativos para artigos.",
        longDescription: "Crie títulos interessantes e criativos para artigos.",
        route: "blog-post-title-ideas-v2",
        useGoogle: false,
        svgUrl: secaoBlogpostIcon,
        fontIcon: "bi-chat-left-quote",
        status: "active",
        category: "Blog",
        useReferences: true,
        numOutputs: 3,
        addToReference: false,
        newUntil: new Date("09-14-2024"),
        filters: ["Blog"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            label: "Assunto do conteúdo",
            placeholder: "Exemplo: Astro Star",
            required: true,
            maxLength: 500,
            tooltip:
              "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            label: "Instruções adicionais",
            placeholder: "Exemplo: Explorar nome de astronautas ou personagens famosos",
            maxLength: 1500,
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "23",
        fields: {
          length: false,
          tone: false,
          language: true
        },
        title: "SEO - Meta Título e Descrição",
        description: "Melhore o ranqueamento dos seus conteúdos nas páginas de pesquisa.",
        longDescription:
          "O modelo “Títulos e Descrições para SEO” é indicado para melhorar o ranqueamento dos seus conteúdos nas páginas de pesquisa. 🔍 Meta Descrição: Pequeno texto que aparece logo abaixo do título e do link de uma página quando se faz uma pesquisa em páginas de pesquisa, como o Google.",
        route: "seo-v2",
        useGoogle: true,
        svgUrl: fundamentosSeoBlogpostIcon,
        fontIcon: "bi-google",
        status: "active",
        category: "SEO",
        videoUrl: "https://player.vimeo.com/video/827973740?h=633a4bac45",
        useReferences: false,
        addToReference: false,
        filters: ["Blog", "Insights"],
        positions: ["Gestor de tráfego", "Redator / Copywriter", "Afiliado"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            label: "Assunto",
            placeholder: "Exemplo: Astro Star",
            maxLength: 500,
            required: true,
            tooltip:
              "Informe sobre o assunto/tema do conteúdo para guiar o resultado, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "instructions",
            field: "textarea",
            label: "Instruções adicionais",
            placeholder: "Exemplo: Usar regras de fórmulas de física",
            maxLength: 1500,
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "25",
        fields: {
          length: false,
          tone: false,
          language: true
        },
        title: "Extrator de Palavras-chave",
        description: "Extraia palavras-chave de um texto.",
        longDescription:
          "O modelo “Extrator de Palavras-chave” é ideal para quem busca palavras-chave para anúncios no Google, por exemplo, a partir de um texto existente.",
        route: "keywords-v2",
        useGoogle: true,
        svgUrl: extratorPalavrasChaveIcon,
        fontIcon: "bi-key",
        status: "active",
        category: "Ads",
        useReferences: false,
        numOutputs: 1,
        addToReference: false,
        filters: ["Anúncios", "Blog", "Insights"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            label: "Conteúdo",
            tooltip: "Insira o texto que deseja que as palavras-chave sejam extraídas",
            placeholder: "Insira o texto",
            maxLength: 3000,
            required: true
          }
        ]
      }
    ]
  },
  headline: {
    id: 7,
    category: "headline",
    useReferences: true,
    name: "Headline",
    description: "Crie títulos impactantes de forma simples.",
    svgUrl: catPaginasIcon,
    usefull: ["Advertorial", "Página de venda", "Lançamento", "VSL", "Thumb de Youtube"],
    models: [
      {
        ...headlineModel
      },
      {
        ...sloganModel
      },
      {
        ...persuasiveTopicsModel
      }
    ]
  },
  prospeccao: {
    id: 11,
    category: "prospeccao",
    useReferences: true,
    name: "Prospecção e Vendas",
    description: "Scripts eficientes para melhorar o resultado das vendas.",
    svgUrl: catProspeccaoVendasIcon,
    usefull: ["Vendas", "Atendimento", "Lançamento", "Performance"],
    models: [
      {
        ...whatsappSellModel
      },
      {
        ...coldCallModel
      },
      {
        ...emailFunnelModel
      }
    ]
  },
  ideas: {
    id: 10,
    category: "ideas",
    useReferences: true,
    name: "Gerador de Ideias",
    description: "Gere infinitas ideias para o que você precisar.",
    svgUrl: catInsightsIcon,
    usefull: ["Desbloquear a criatividade", "Performance"],
    models: [
      {
        ...postsIdeasModel
      },
      {
        ...mktPlanIdeasModel
      },
      {
        ...sloganModel
      },
      {
        ...socialMediaTimelineModel
      },
      {
        ...mktRidiculousIdeasModel
      },
      {
        ...imageCreativesIdeasModel
      }
    ]
  },
  "landing-page": {
    id: 15,
    category: "landing-page",
    useReferences: true,
    soon: false,
    name: "Landing Page",
    description: "Crie textos completos para landing pages de alta conversão.",
    svgUrl: catLandingPageIcon,
    usefull: ["Lançamento", "Performance", "Vendas", "E-commerce"],
    models: [
      {
        id: "200",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Landing Page Vendas",
        shortTitle: "LP Vendas",
        description:
          "Converta visitantes em compradores, realizando vendas diretamente ou capturando leads para conversão posterior.",
        route: "landing-page-sell-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useSeo: true,
        usePersona: true,
        useReferences: true,
        svgUrl: landingVendasIcon,
        fontIcon: "bi-currency-dollar",
        status: "active",
        category: "Landing Page",
        addToReference: false,
        soon: false,
        newUntil: new Date("09-14-2024"),
        filters: ["Vendas", "Anúncios", "E-commerce"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 1500,
            required: true,
            label: "O que está à venda",
            placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
            tooltip:
              "Informe sobre o produto ou serviço que é sendo anunciado na página. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "name",
            field: "textarea",
            maxLength: 500,
            required: false,
            label: "Preço ou oferta única",
            placeholder: "15% OFF: R$ 2.500,00/ mês",
            tooltip:
              "Informe o preço, ou caso tenha, cite a oferta única (condição especial) apresentada na página. Exemplo: valor, formas de pagamento, data limie, lotes"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Explorar que está com preço de lançamento e são vagas limitadas.",
            tooltip: "Adicione mais informações para compor/guiar o conteúdo da página."
          },
          {
            type: "extra",
            field: "select",
            label: "Gerar com FAQ",
            tooltip:
              "FAQ: Objetivo: Abordar dúvidas comuns e reduzir barreiras à compra. Estratégia: Liste as perguntas mais frequentes com respostas claras e concisas. Escolha se deseja que a o conteúdo seja gerado com  sugestão de FAQ. Exemplo: 'Qual é o tempo de garantia?' ou 'Como faço para solicitar a devolução?'",
            placeholder: "Selecione a plataforma da sua postagem",
            required: true,
            options: [
              { value: "no", label: "Não" },
              { value: "yes", label: "Sim" }
            ]
          }
        ]
      },
      {
        id: "201",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: 'Landing Page "Em Breve"',
        shortTitle: "LP Em Breve",
        description: "Crie antecipação e colete informações de contato antes do lançamento de um produto ou serviço.",
        route: "landing-page-soon-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useSeo: true,
        useReferences: true,
        svgUrl: landingEmBreveIcon,
        fontIcon: "bi-calendar-event",
        status: "active",
        category: "Landing Page",
        addToReference: false,
        soon: false,
        newUntil: new Date("09-14-2024"),
        filters: ["Anúncios"],
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está sendo divulgado",
            placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
            tooltip:
              "Informe sobre o produto ou serviço que é citado no e-mail, caso deseje, pode dar detalhamentos como objetivo/foco do conteúdo. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: Astro Star."
          },
          {
            type: "name",
            field: "input",
            maxLength: 150,
            required: false,
            label: "Data de Lançamento",
            placeholder: "Exemplo: Dia 27/01/2027. Daqui a 30 dias.",
            tooltip: "Informe a data de lançamento para poder usar a urgência e elevar a expectativa do público."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Não revelar o nome do produto. Cria antecipação e explore a curiosidade.",
            tooltip:
              "Adicione mais informações para guiar o resultado. Exemplo: valores, data, local, testemunhos, informações de contato ou instruções de estratégias, como usar dores da persona, escassez e outras."
          }
        ]
      },
      {
        id: "202",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Landing Page de Informação de Produto",
        shortTitle: "LP Informação do Produto",
        description:
          "Apresente detalhes e características de um produto ou serviço específico, educando os visitantes e incentivando a decisão de compra.",
        route: "landing-page-product-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useSeo: false,
        usePersona: true,
        useReferences: true,
        svgUrl: landingProdutoIcon,
        fontIcon: "bi-info-circle",
        status: "active",
        category: "Landing Page",
        addToReference: false,
        soon: false,
        newUntil: new Date("09-14-2024"),
        filters: ["Branding"],
        inputSchema: [
          {
            type: "extra",
            field: "select",
            label: "Selecione o objetivo",
            tooltip:
              "Selecione o objetivo final da landing page: - Incentivar os visitantes a agendar uma demonstração do produto ou uma consulta com um representante de vendas. - Informar os visitantes sobre os benefícios, funcionalidades e diferenciais do produto, construindo uma base para futuras decisões de compra. - Direcionar os visitantes para outras plataformas de vendas, como e-commerce, lojas físicas ou parceiros de distribuição. - Estabelecer um relacionamento contínuo com os visitantes, oferecendo conteúdo valioso, suporte e incentivos para futuras interações. - Outro: Especifique o objetivo.",
            placeholder: "Escolha um objetivo para a landing page",
            required: true,
            options: [
              { value: "Scheduling demos", label: "Agendamento de demonstrações" },
              {
                value: "Presentation and education Redirection to sales channels",
                label: "Apresentação e educação Redirecionamento para canais de vendas"
              },
              { value: "Customer relationship building", label: "Construção de Relacionamento com o cliente" },
              { value: "Other", label: "Outro" }
            ]
          },
          {
            type: "description",
            field: "textarea",
            maxLength: 1500,
            required: true,
            label: "Nome e características do produto",
            placeholder:
              "Exemplo: Treinamento exclusivo para astronautas, Feito por astronaustas experientes, estudos e informações privilegiadas.",
            tooltip:
              "Descreva de forma clara as principais características do produto que será divulgadol na página. Benefícios, diferenciais, dor resolvida, garantia e etc"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Usada tecnologia de ponta e simuladores.",
            tooltip: "Adicione mais informações para compor/guiar o conteúdo da página."
          }
        ]
      },
      {
        id: "203",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Landing Page de Captura de Leads",
        shortTitle: "LP Captura de Leads",
        description: "Transforme visitantes em leads oferecendo algo interessante.",
        route: "landing-page-leads-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useSeo: false,
        usePersona: true,
        useReferences: true,
        svgUrl: landingLeadsIcon,
        fontIcon: "bi-chat-right-heart-fill",
        status: "active",
        category: "Landing Page",
        addToReference: false,
        soon: false,
        newUntil: new Date("09-14-2024"),
        filters: ["Anúncios"],
        inputSchema: [
          {
            type: "name",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está sendo oferecido",
            placeholder: "Exemplo: Kit futuro astronauta",
            tooltip:
              "Informações completas sobre o que está sendo divulgado para despertar interesse e capturar os leads. Isso inclui descrições de produtos, serviços, eventos ou recursos (como e-books ou webinars) que você está promovendo."
          },
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "Benefícios da oferta",
            placeholder:
              "Exemplo: Ebook sobre a história da engenharia aeroespacial incluindo materiais exclusivos das principais pesquisas dos anos 2000",
            tooltip:
              "Os principais benefícios que os visitantes ganharão ao se tornarem leads, ou seja ao se inscreverem. Isso pode ser acesso a informações exclusivas, benefícios de um produto ou serviço, descontos, etc."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            required: false,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Explorar que está gratuito por tempo limitado",
            tooltip: "Adicione mais informações para compor/guiar o conteúdo da página."
          }
        ]
      },
      {
        id: "204",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Landing Page de Obrigado",
        shortTitle: "LP Obrigado",
        description: "Agradeça aos visitantes depois de realizarem uma ação específica em um site.",
        route: "landing-page-thankyou-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useSeo: false,
        usePersona: false,
        useReferences: true,
        svgUrl: landingObrigadoIcon,
        fontIcon: "bi-balloon-heart",
        status: "active",
        category: "Landing Page",
        addToReference: false,
        soon: false,
        newUntil: new Date("09-14-2024"),
        filters: ["Anúncios", "E-commerce", "Vendas"],
        inputSchema: [
          {
            type: "extra",
            field: "select",
            label: "Selecione o objetivo",
            tooltip:
              "Selecione o objetivo final da landing page. - Confirmar a ação realizada: Confirmar ao usuário que sua ação foi completada com sucesso, como a compra, inscrição ou envio de formulário. - Fornecer próximos passos: Orientar o usuário sobre o que acontecerá a seguir, como a entrega de um produto, o recebimento de um e-mail ou a próxima etapa do processo. - Incentivar ações adicionais: Aproveitar o momento para engajar o usuário em ações adicionais, como seguir nas redes sociais, compartilhar a oferta, ou explorar mais produtos ou conteúdos. - Reforçar a confiança: Reforçar a decisão do usuário, agradecendo e fornecendo conteúdo ou suporte adicional. - Outro: Especifique o objetivo.",
            placeholder: "Escolha um objetivo para a landing page",
            required: true,
            options: [
              { value: "Confirm the action taken", label: "Confirmar a ação realizada" },
              { value: "Encourage further action", label: "Fornecer próximos passos" },
              { value: "Reinforce trust", label: "Reforçar a confiança" },
              { value: "Other", label: "Outro" }
            ]
          },
          {
            type: "name",
            field: "textarea",
            maxLength: 1500,
            required: true,
            label: "Detalhes da ação concluída",
            placeholder: "Exemplo: Comprou a mentoria Super Star",
            tooltip:
              "Descreva o que o visitante acabou de fazer ou obter. Por exemplo, se inscreveu para um webinar, baixou um e-book, ou se cadastrou para receber uma newsletter."
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Usada tecnologia de ponta e simuladores.",
            tooltip: "Adicione mais informações para compor/guiar o conteúdo da página."
          }
        ]
      }
    ]
  },
  "email-cadence": {
    id: 15,
    category: "email-cadence",
    useReferences: true,
    soon: false,
    name: "Fluxo de cadência de e-mail",
    description: "Crie fluxos de e-mails completos para lançamentos, vendas e outros objetivos específicos.",
    svgUrl: catEmailIcon,
    usefull: ["Lançamento", "Performance", "Vendas", "E-commerce"],
    models: [
      {
        id: "210",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Fluxo para carrinho abandonado",
        shortTitle: "Fluxo para carrinho abandonado",
        description:
          "Tente recuperar visitantes que abandonaram seu carrinho de compras. Use esse fluxo para reativar vendas perdidas e aumentar a conversão.",
        route: "cadence-email-cart-abandoned-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailBoasVindasIcon,
        fontIcon: "bi-envelope",
        status: "active",
        category: "Fluxo de Cadência",
        addToReference: false,
        filters: ["E-mails", "Vendas", "E-commerce"],
        newUntil: new Date("09-14-2024"),
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está à venda",
            placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
            tooltip:
              "Informe sobre o produto ou serviço que é sendo anunciado na página. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "name",
            field: "textarea",
            maxLength: 500,
            required: false,
            label: "Oferta única",
            placeholder: "15% OFF: R$ 2.500,00/ mês",
            tooltip:
              "Caso tenha, cite a oferta apresentada no e-mail, condições únicas. Exemplo: valor, formas de pagamento, data limie, lotes"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo do e-mail. Exemplo: formas de pagamento, garantia, diferenciais do produto e etc."
          }
        ]
      },
      {
        id: "211",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Fluxo para e-mail de vendas",
        shortTitle: "Fluxo para e-mail de vendas",
        description: "Crie texto com objetivo de convencer o destinatário a tomar uma decisão de compra.",
        route: "cadence-email-sales-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailVendasIcon,
        fontIcon: "bi-envelope",
        status: "active",
        category: "Fluxo de Cadência",
        addToReference: false,
        filters: ["E-mails", "Vendas", "E-commerce"],
        newUntil: new Date("09-14-2024"),
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está à venda",
            placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
            tooltip:
              "Informe sobre o produto ou serviço que é sendo anunciado na página. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "name",
            field: "textarea",
            maxLength: 500,
            required: false,
            label: "Oferta única",
            placeholder: "15% OFF: R$ 2.500,00/ mês",
            tooltip:
              "Caso tenha, cite a oferta apresentada no e-mail, condições únicas. Exemplo: valor, formas de pagamento, data limie, lotes"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo do e-mail. Exemplo: formas de pagamento, garantia, diferenciais do produto e etc."
          }
        ]
      },
      {
        id: "212",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Fluxo para oferta de Upsell",
        shortTitle: "Fluxo para oferta de Upsell",
        description: "Crie um texto para aumentar o valor médio das compras de clientes.",
        route: "cadence-email-upsell-v2",
        noAccessPlans: ["BWP5", "BWP6", "BWP7"],
        useGoogle: false,
        useReferences: true,
        svgUrl: emailUpsellIcon,
        fontIcon: "bi-envelope",
        status: "active",
        category: "Fluxo de Cadência",
        addToReference: false,
        filters: ["E-mails", "Vendas", "E-commerce"],
        newUntil: new Date("09-14-2024"),
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 500,
            required: true,
            label: "O que está à venda",
            placeholder: "Exemplo: Treinamento para astronautas. Instrutores experientes e infraestrutura de ponta.",
            tooltip:
              "Informe sobre o produto ou serviço que é sendo anunciado na página. Com o treinamento da IA ativo não é necessário detalhar, basta citar nomes/elementos que já são conhecidos pela intelligência. Exemplo: início das vendas do Astro Star."
          },
          {
            type: "name",
            field: "textarea",
            maxLength: 500,
            required: false,
            label: "Oferta única",
            placeholder: "15% OFF: R$ 2.500,00/ mês",
            tooltip:
              "Caso tenha, cite a oferta apresentada no e-mail, condições únicas. Exemplo: valor, formas de pagamento, data limie, lotes"
          },
          {
            type: "instructions",
            field: "textarea",
            maxLength: 1500,
            label: "Instruções adicionais",
            placeholder: "Exemplo: Duração de 6 meses. Na Flórida, em setembro de 2027",
            tooltip:
              "Adicione mais informações para compor o conteúdo do e-mail. Exemplo: formas de pagamento, garantia, diferenciais do produto e etc."
          }
        ]
      }
    ]
  },
  chat: {
    id: 12,
    category: "chat",
    useReferences: true,
    name: "Chat Baseworks",
    description: "Peça o que quiser para nossa I.A. e ela vai obedecer a sua ordem!",
    svgUrl: catChatInteligenteIcon,
    usefull: ["Geral"],
    hideModels: true,
    models: []
  },
  "article-wizard": {
    id: 13,
    category: "article-wizard",
    useReferences: true,
    name: "Gerador de Documentos",
    description: "Gere um artigo para blog com até 2.000 palavras em 5 minutos.",
    svgUrl: catCopydocsIcon,
    usefull: ["Artigo", "Blog"],
    hideModels: true,
    models: [
      {
        id: "99",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Artigo com título",
        description: "Emails de vendas poderosos para seu produto",
        route: "email-vendas",
        svgUrl: "media/icons/bootstrap/envelope-open-fill.svg",
        fontIcon: "bi-envelope-open-fill",
        status: "active",
        category: "blog",
        outputsNum: 3,
        inputSchema: [
          {
            label: "Título do artigo",
            placeholder: "Exemplo: Adaptação de humanos para a vida em Marte",
            type: "name",
            field: "input",
            maxLength: 150
          }
        ]
      },
      {
        id: "1002",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Artigo com título",
        description: "Emails de vendas poderosos para seu produto",
        route: "email-vendas",
        svgUrl: "media/icons/bootstrap/envelope-open-fill.svg",
        fontIcon: "bi-envelope-open-fill",
        status: "active",
        category: "blog",
        outputsNum: 3,
        inputSchema: [
          {
            label: "Título do artigo",
            placeholder: "Exemplo: Adaptação de humanos para a vida em Marte",
            type: "name",
            field: "input",
            maxLength: 150
          },
          {
            type: "keywords",
            field: "keywords",
            maxLength: 500,
            label: "Palavra-chave de foco",
            placeholder: "Exemplo: vida em Marte"
          }
        ]
      },
      {
        id: "10321",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Artigo sem título",
        description: "Emails de vendas poderosos para seu produto",
        route: "email-vendas",
        svgUrl: "media/icons/bootstrap/envelope-open-fill.svg",
        fontIcon: "bi-envelope-open-fill",
        status: "active",
        category: "blog",
        outputsNum: 3,
        inputSchema: [
          {
            label: "Qual o assunto do artigo?",
            placeholder: "Exemplo: Adaptação de humanos para a vida em Marte",
            type: "name",
            field: "input",
            maxLength: 150
          }
        ]
      },
      {
        id: "102",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Artigo sem título",
        description: "Emails de vendas poderosos para seu produto",
        route: "email-vendas",
        svgUrl: "media/icons/bootstrap/envelope-open-fill.svg",
        fontIcon: "bi-envelope-open-fill",
        status: "active",
        category: "blog",
        outputsNum: 5,
        inputSchema: [
          {
            label: "Qual o assunto do artigo?",
            placeholder: "Exemplo: Adaptação de humanos para a vida em Marte",
            type: "name",
            field: "input",
            maxLength: 150
          },
          {
            type: "keywords",
            field: "keywords",
            maxLength: 500,
            label: "Palavra-chave de foco",
            placeholder: "Exemplo: vida em Marte"
          }
        ]
      }
    ]
  },
  images: {
    id: 14,
    category: "images",
    useReferences: true,
    name: "Gerador de Imagens",
    description: "Gere imagens e variações personalizadas (para todas as demandas).",
    svgUrl: catGeradorImagensIcon,
    usefull: ["Geral"],
    hideModels: true,
    models: []
  },
  popup: {
    id: 2324,
    category: "popup",
    useReferences: true,
    name: "Modelos Popup",
    description: "",
    svgUrl: "",
    usefull: [],
    hideModels: true,
    models: [
      {
        id: "2911",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Introdução de artigo",
        description: "Crie um parágrafo de introdução para um artigo de blog",
        route: "blog-intro",
        useGoogle: true,
        svgUrl: edit,
        fontIcon: "bi-blockquote-left",
        status: "active",
        category: "Blog",
        numOutputs: 1,
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 1000,
            label: "Descreva o que será abordado no seu artigo",
            placeholder: "Qual o assunto ou título do artigo?"
          }
        ]
      },
      {
        id: "3441",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Conclusão de artigo",
        description: "Crie um parágrafo para concluir um artigo de blog",
        route: "blog-conclusao",
        useGoogle: true,
        svgUrl: conclusion,
        fontIcon: "bi-blockquote-right",
        status: "active",
        category: "Blog",
        numOutputs: 3,
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 1000,
            label: "Descreva sua conclusão"
          }
        ]
      },
      {
        id: "31231",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Seções para artigo de blog",
        description: "Crie subtítulos (h2) para seu artigo de blog",
        route: "blog-topics",
        useGoogle: true,
        svgUrl: paragraph,
        fontIcon: "bi-blockquote-right",
        status: "active",
        category: "Blog",
        numOutputs: 3,
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 1000,
            label: "Descreva o que será abordado no seu artigo",
            placeholder: "Informe o título, assunto ou descrição do artigo"
          }
        ]
      },
      {
        id: "32",
        fields: {
          length: true,
          tone: true,
          language: true
        },
        title: "Parágrafo de seção de artigo",
        description: "Gere um parágrafo para uma seção (subtítulo) de blog post",
        route: "blog-post",
        useGoogle: true,
        svgUrl: edit,
        fontIcon: "bi-chat-left-quote",
        status: "active",
        category: "Blog",
        numOutputs: 3,
        inputSchema: [
          {
            type: "description",
            field: "textarea",
            maxLength: 1000,
            label: "Sobre o que você quer escrever?",
            placeholder: "Informe o subtítulo (H2) para o qual deseja escrever um parágrafo"
          }
        ]
      },
      {
        ...headlineModel
      },
      {
        ...persuasiveTopicsModel
      },
      {
        ...socialMediaTimelineModel
      },
      {
        ...postCaptionModel
      },
      {
        ...videoCreativesIdeasModel
      },
      {
        ...imageCreativesIdeasModel
      },
      {
        id: "38",
        fields: {
          length: false,
          tone: true,
          language: true
        },
        title: "Ideias de post para Instagram",
        description: "Gere novas ideias para conteúdos para aumentar engajamento",
        longDescription: "",
        route: "ideas",
        useGoogle: true,
        svgUrl: "",
        fontIcon: "bi-lightbulb",
        status: "active",
        category: "Criativo",
        videoUrl: "https://player.vimeo.com/video/827973783?h=7c72559542",
        useReferences: true,
        addToReference: false,
        inputSchema: [
          {
            label: "Para qual a marca/produto/serviço?",
            placeholder: "Exemplo: Astro Star",
            type: "name",
            field: "input",
            maxLength: 120,
            info: {
              title: "Para qual a marca/produto/serviço?",
              subtitle: "Dúvidas sobre como preencher?",
              list: ["Insira o nicho da marca, ou função do produto/serviço"],
              examples: ["Baseworks - Gerador de Textos focado em marketing com inteligência artificial"]
            }
          }
        ]
      },
      {
        ...videoScriptModel
      },
      {
        ...productBenefitsModel
      },
      {
        ...productDescModel
      },
      {
        ...productReviewModel
      },
      {
        ...sellsEmailModel
      },
      {
        ...welcomeEmailModel
      },
      {
        ...prospectEmailModel
      },
      {
        ...emailCartModel
      },
      {
        ...emailFunnelModel
      },
      {
        ...aidaModel
      },
      {
        ...babModel
      },
      {
        ...pasModel
      },
      {
        ...ppppModel
      },
      {
        ...whatsappSellModel
      },
      {
        ...coldCallModel
      },
      {
        ...mktPlanIdeasModel
      },
      {
        ...sloganModel
      },
      {
        ...mktRidiculousIdeasModel
      }
    ]
  }
};

export const steps = [
  {
    // 1
    phase: 0
  },
  {
    // 2
    phase: 0
  },
  {
    // 3
    phase: 0
  },
  {
    // 4
    phase: 1
  },
  {
    // 5
    phase: 2
  },
  {
    // 6
    phase: 3
  },
  {
    // 7
    phase: 4
  },
  {
    // 8
    phase: 4
  },
  {
    phase: null
  }
];

export const phases = [
  {
    name: "Cadastro Inicial"
  },
  {
    name: "Tom de voz"
  },
  {
    name: "Persona"
  },
  {
    name: "Referências"
  },
  {
    name: "Configurações"
  }
];

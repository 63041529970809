export interface ProxyStorage<T> {
  getItem(key: string): T | null;
  setItem(Key: string, value: T): void;
  removeItem(key: string): void;
  clear(): void;
}

//sessionStorage operate
export class sessionStorageProxy<T> implements ProxyStorage<T> {
  protected storage: ProxyStorage<T>;

  constructor(storageModel: ProxyStorage<T>) {
    this.storage = storageModel;
  }

  public setItem(key: string, value: T): void {
    this.storage.setItem(key, JSON.stringify(value) as T);
  }

  public getItem(key: string): T | null {
    const item = this.storage.getItem(key);
    return item ? JSON.parse(item as string) : null;
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
    localStorage.clear();
    sessionStorage.clear();
  }
}

//localStorage operate
class localStorageProxy<T> extends sessionStorageProxy<T> implements ProxyStorage<T> {
  constructor(localStorage: ProxyStorage<T>) {
    super(localStorage);
  }
}

export const storageSession = new sessionStorageProxy<unknown>(sessionStorage);

export const storageLocal = new localStorageProxy<unknown>(localStorage);

// Core
import { AllowedComponentProps, Component, VNodeProps } from "vue";

// Libraries
import { TYPE, useToast } from "vue-toastification";

// Services & Helpers
import { handleRuntimeErrors } from "@/helpers/error-handler";

// Components
import ToastItem from "@/components/toast/ToastItem.vue";
// import ProgressToast from "@/components/project/projectCreation/partials/ProgressToast.vue";

// Types
import { ToastOptions } from "vue-toastification/dist/types/types";

type ComponentProps<C extends Component> = C extends new (...args: unknown[]) => unknown
  ? Omit<InstanceType<C>["$props"], keyof VNodeProps | keyof AllowedComponentProps>
  : never;

type CustomToastOptions<T extends Component> = ComponentProps<T> & { type?: TYPE };

export const useCustomToast = <T extends Component>(component: T) => {
  const _toast = useToast();

  if (!(component as { props: object })?.props.hasOwnProperty("type")) {
    handleRuntimeErrors("O componente passado para useCustomToast deve ter uma propriedade 'type'.");
  }

  const trigger = (options: CustomToastOptions<T>, pluginOptions?: ToastOptions) => {
    _toast(
      {
        component,
        props: options
      },
      pluginOptions
    );
  };

  const showToast = (type: TYPE, options: CustomToastOptions<T>, pluginOptions?: ToastOptions) => {
    trigger(
      {
        ...options,
        type
      },
      pluginOptions
    );
  };

  const success = (options: CustomToastOptions<T>, pluginOptions?: ToastOptions) => {
    showToast(TYPE.SUCCESS, options, pluginOptions);
  };

  const info = (options: CustomToastOptions<T>, pluginOptions?: ToastOptions) => {
    showToast(TYPE.INFO, options, pluginOptions);
  };

  const warning = (options: CustomToastOptions<T>, pluginOptions?: ToastOptions) => {
    showToast(TYPE.WARNING, options, pluginOptions);
  };

  const error = (options: CustomToastOptions<T>, pluginOptions?: ToastOptions) => {
    showToast(TYPE.ERROR, options, pluginOptions);
  };

  return {
    showToast,
    success,
    info,
    warning,
    error
  };
};

// NOTE: O componente passado como parâmetro do composable **deve ter a propriedade type**.
//       O componente a ser renderizado será o passado como parâmetro, seus props serão passados para
//       options de cada método do composable.
export const toast = useCustomToast(ToastItem);
// export const progressProjectToast = useCustomToast(ProgressToast);

// Libraries
import mixpanel from "mixpanel-browser";

// Types
import { UserDetailsAdmin } from "@/@types/user";
interface AnalyticsEvent {
  name: string;
  properties?: Record<string, unknown>
}
interface AnalyticsProvider {
  initialize(): Promise<void>;
  trackEvent(event: AnalyticsEvent): Promise<void>;
  setUser(user: UserDetailsAdmin): Promise<void>;
  incrementUserProperty(property: string, value: number): Promise<void>;
  identify(id: string): Promise<void>;
  reset(): Promise<void>;
}

class MixpanelProvider implements AnalyticsProvider {
  private mixpanelInitialized: boolean = false;
  private initializationPromise: Promise<void> | null = null;
  private mixpanelToken: string;

  constructor(mixpanelToken: string) {
    this.mixpanelToken = mixpanelToken;
  }

  async initialize(): Promise<void> {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = new Promise<void>((resolve, reject) => {
      try {
        mixpanel.init(this.mixpanelToken, {
          debug: import.meta.env.VITE_NODE_ENV !== "production",
          cross_subdomain_cookie: true, 
          loaded: () => {
            this.mixpanelInitialized = true;
            if (import.meta.env.VITE_NODE_ENV !== "production") {
              console.log('Mixpanel carregado com sucesso');
            }
            resolve();
          }
        });
      } catch (error) {
        console.error("Erro ao inicializar Mixpanel:", error);
        reject(error);
      }
    });

    return this.initializationPromise;
  }

  private async ensureInitialized(): Promise<void> {
    if (!this.mixpanelInitialized) {
      await this.initialize();
    }
  }

  async trackEvent(event: AnalyticsEvent): Promise<void> {
    await this.ensureInitialized();
    mixpanel.track(event.name, event.properties);
  }

  async setUser(user: UserDetailsAdmin): Promise<void> {
    await this.ensureInitialized();

    mixpanel.identify(user.id);
    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $first_name: user.firstName,
      $last_name: user.lastName,
      $email: user.email,
      $phone: user.phoneNumber,
      "Time | ID": user.activeTeam?.id,
      "Time | Nome": user.activeTeam?.name,
      "Time | Nível acesso": user.activeTeam?.role,
      "Preferências": user.preferences,
      "Última atualização": user.updatedAt ? new Date(user.updatedAt).toISOString() : "",
    });
    mixpanel.people.set_once({
      "Sign up Date": new Date(user.createdAt).toISOString()
    });
  }

  async incrementUserProperty(property: string, value: number): Promise<void> {
    await this.ensureInitialized();
    mixpanel.people.increment(property, value);
  }

  async identify(id: string): Promise<void> {
    await this.ensureInitialized();
    mixpanel.identify(id);
  }

  async reset(): Promise<void> {
    await this.ensureInitialized();
    mixpanel.reset();
  }
}


class AnalyticsService {
  private static instance: AnalyticsService;
  private provider: AnalyticsProvider;

  private constructor(provider: AnalyticsProvider) {
    this.provider = provider;
  }

  static async getInstance(mixpanelToken: string): Promise<AnalyticsService> {
    if (!this.instance) {
      const provider = new MixpanelProvider(mixpanelToken);
      await provider.initialize();
      this.instance = new AnalyticsService(provider);
    }
    return this.instance;
  }

  async trackEvent(event: AnalyticsEvent): Promise<void> {
    await this.provider.trackEvent(event);
  }

  async setUser(user: UserDetailsAdmin): Promise<void> {
    await this.provider.setUser(user);
  }

  async incrementUserProperty(property: string, value: number): Promise<void> {
    await this.provider.incrementUserProperty(property, value);
  }

  async identify(id: string): Promise<void> {
    await this.provider.identify(id);
  }

  async reset(): Promise<void> {
    await this.provider.reset();
  }
}

let analyticsInstance: AnalyticsService | null = null;

export const initializeAnalytics = async (): Promise<void> => {
  const mixpanelToken = import.meta.env.VITE_NODE_ENV === "production" ? import.meta.env.VITE_MIXPANEL_PROD_KEY : import.meta.env.VITE_MIXPANEL_DEV_KEY;
  analyticsInstance = await AnalyticsService.getInstance(mixpanelToken);
};

export const analyticsTrackEvent = async (event: AnalyticsEvent): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");
    return;
  }
  await analyticsInstance.trackEvent(event);
};

export const analyticsSetUser = async (user: UserDetailsAdmin): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");
    return;
  }
  await analyticsInstance.setUser(user);
};

export const analyticsIncrementUserProperty = async (property: string, value: number): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");
    return;
  }
  await analyticsInstance.incrementUserProperty(property, value);
};

export const analyticsIdentify = async (id: string): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");
    return;
  }
  await analyticsInstance.identify(id);
};

export const analyticsReset = async (): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");
    return;
  }
  await analyticsInstance.reset();
};
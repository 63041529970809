<script setup lang="ts">
// Libraries
import { useHead } from "@vueuse/head";
import { FixedResizeObserver } from "./utils/resize-observer";


useHead({
  title: "Baseworks - Inteligência Artificial para Marketing"
});

//Stop error resizeObserver
window.ResizeObserver = FixedResizeObserver;

</script>

<template>
  <router-view />
</template>

<style lang="scss">
@import "assets/sass/style";
@import "element-plus/dist/index.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

.el-card.form {
  box-shadow: 0px 0px 4px #00000033 !important;
}

.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  font-size: 16px;
  color: #000;
  padding-left: 10px;
}

.el-checkbox__inner {
  width: 20px;
  height: 20px;
}

.el-checkbox__inner::after {
  height: 10px;
  width: 5px;
  left: 6px;
  top: 2px;
}

.btn-lg {
  height: 60px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 18px;
}

.el-notification__content {
  text-align: start !important;
}
</style>

// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";

// Stores
import { useUserStore } from "@/store/user";
import { useTeamStore } from "@/store/team";

const requireOnboarding: NavigationGuard = async (_, __, next) => {
  try {
    const userStore = useUserStore(pinia);
    const teamStore = useTeamStore(pinia);

    const infos = userStore.userInfos;

    if (teamStore.teamIsLeadMachineOnly) return next();

    const onboarding = infos?.onboarding;
    const completed = onboarding?.hasCompleted || onboarding?.hasCompletedStep2;

    if (completed) return next();

    const step = onboarding?.currentStep;

    const pendingInvite = teamStore.pendingInvites.length;

    if (pendingInvite) return next("/sign-up");

    if (step === 6) return next("subscription");

    if (step === 5) return next("meetbase-onboarding");

    return next("sign-up");
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireOnboarding;

import moment from "moment";

export const getSeconds = (d: string) => {
  const isoDate = moment.utc(d);
  const localDate = isoDate.local();
  const localTime = localDate.unix();

  return localTime;
};

export const timeSince = (timeStamp: number) => {
  if (!timeStamp || isNaN(timeStamp) || timeStamp < 1) {
    return " agora";
  } else if (timeStamp < 60) {
    const minutes = Math.round(timeStamp);
    return " há " + minutes + ` ${minutes == 1 ? "minuto" : "minutos"}`;
  } else if (timeStamp < 1440) {
    const hours = Math.round(timeStamp / 60);
    return " há " + hours + ` ${hours == 1 ? "hora" : "horas"}`;
  } else {
    const days = Math.round(timeStamp / 1440);
    return " há " + days + ` ${days == 1 ? "dia" : "dias"}`;
  }
};

export const currentTimeDiff = (givenTimeInSeconds: number) => {
  const ISODate = new Date().toISOString(); // NOTE: toISOString para evitar warning
  const currentTime = getSeconds(ISODate);

  const difference = (currentTime - givenTimeInSeconds) / 60;

  return difference;
};

export const calculateDifference = (givenTimeInSeconds: number) => {
  const difference = currentTimeDiff(givenTimeInSeconds);
  return timeSince(difference);
};

export const sleep = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const millisToMinutes = (millis: number): number => Math.floor(millis / 60000);

export class PausableTimeout {
  private timeoutId: NodeJS.Timeout | null = null;
  private start: number;
  private remaining: number;

  constructor(
    private callback: () => void,
    delay: number
  ) {
    this.remaining = delay;
    this.start = new Date().getTime();
    this.resume();
  }

  pause() {
    if (!this.timeoutId) return;

    clearTimeout(this.timeoutId);
    this.remaining -= new Date().getTime() - this.start;
    this.timeoutId = null;
  }

  resume() {
    if (this.timeoutId) return;

    this.start = new Date().getTime();
    this.timeoutId = setTimeout(this.callback, this.remaining);
  }

  clear() {
    if (!this.timeoutId) return;

    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }
}

export const daysDiff = (date1: string, date2: string) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);

  const diffTime = Math.abs(date2Obj.getTime() - date1Obj.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const millisToMinutesAndSeconds = (millis: number) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = parseInt(((millis % 60000) / 1000).toFixed(0));

  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};
